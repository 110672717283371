import React from "react";
import Page from "../../components/Page";
import Button from "../../components/Button";
import Box from "../../components/Box";
import { Link } from "react-router-dom";
import s from "./style.module.css";

import { home } from "../../strings";

const Home = () => {
  return (
    <Page title={home.title} showcase>
      <div className={s.strapline}>
        <img className={s.logo} src="/logo-small.svg" alt="" />

        <p className={s.lead}>{home.strapline}</p>

        {home.strapline2 && <p>{home.strapline2}</p>}
      </div>

      <div className={s.sections}>
        {home.sections.map(({ name, copy, url }) => (
          <Box tight className={s.section} key={url}>
            <div className={s.header}>
              <h2 className={s.name}>{name}</h2>
              <p className={s.copy}>{copy}</p>
            </div>
            <Button tag={Link} to={url} iconAfter={"arrowRight"}>
              {home.buttonText}
            </Button>
          </Box>
        ))}
      </div>
    </Page>
  );
};

export default Home;
