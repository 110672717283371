import React, { useRef, useState, useEffect } from "react";
import Button from "../Button";
import s from "./style.module.css";

const filtersToArray = (filters) =>
  Object.keys(filters).map((key) => ({
    colName: key,
    value: filters[key],
  }));

const FilterOptionBlank = ({
  id,
  setSelectedColName,
  filterableOptions,
  removeOption,
  reference,
  text,
}) => (
  <div>
    <select
      name={id}
      onChange={(e) => setSelectedColName(id, e.target.value)}
      ref={reference}
    >
      <option value={-1}>{text.nullState}</option>
      {filterableOptions.map(({ colName, prettyName }) => (
        <option key={colName} value={colName}>
          {prettyName}
        </option>
      ))}
    </select>
  </div>
);

const FilterOptionSelected = ({
  id,
  colName,
  prettyName,
  setValue,
  options,
  value,
  reference,
  removeOption,
  text,
}) => (
  <div className={s.filterOptionInner}>
    <span className={s.filterOptionHeader}>
      <Button
        className={s.removeButton}
        onClick={(e) => {
          e.preventDefault();
          removeOption(id);
        }}
        tooltip={text.removeFilter}
        iconBefore="minus"
      />
      <label>{prettyName}</label>
    </span>
    <select
      name={colName}
      onChange={(e) => setValue(id, e.target.value)}
      value={value}
      ref={reference}
    >
      <option value={-1}>{text.nullState}</option>
      {options.map((option) => (
        <option key={option}>{option}</option>
      ))}
    </select>
  </div>
);

const FilterSchools = ({
  filterableOptions = [],
  filters,
  clearFilters,
  onFilter,
  meta,
  text,
}) => {
  const form = useRef(null);
  const nextDropdown = useRef(null);
  const [waitingForSelected, setWaitingForSelected] = useState(false);
  const [addedFilters, setAddedFilters] = useState(filtersToArray(filters));

  const addOption = () => {
    setWaitingForSelected(true);
    setAddedFilters([...addedFilters, { colName: -1 }]);
  };

  const removeOption = (i) => {
    setAddedFilters([
      ...addedFilters.slice(0, i),
      ...addedFilters.slice(i + 1),
    ]);
  };

  const setSelectedColName = (i, colName) => {
    setAddedFilters([
      ...addedFilters.slice(0, i),
      { ...addedFilters[i], colName },
      ...addedFilters.slice(i + 1),
    ]);
    setWaitingForSelected(false);
  };

  const setValue = (i, value) => {
    setAddedFilters([
      ...addedFilters.slice(0, i),
      { ...addedFilters[i], value },
      ...addedFilters.slice(i + 1),
    ]);
  };

  const addMetaToFilters = addedFilters.map((filter) => ({
    ...filter,
    ...(meta.find((d) => d.colName === filter.colName) || {}),
  }));

  const selectedFilters = addedFilters.map((d) => d.colName);
  const filterableOptionsWithoutAlreadySelected = filterableOptions.filter(
    (d) => !selectedFilters.includes(d.colName)
  );

  useEffect(() => {
    const filterSchools = () => {
      const formData = new FormData(form.current);
      const filterss = {};
      for (let entry of formData.entries()) {
        const [name, value] = entry;
        if (value !== "-1") {
          filterss[name] = value;
        }
      }

      if (JSON.stringify(filterss) !== JSON.stringify(filters)) {
        onFilter(filterss);
      }
    };

    if (nextDropdown.current) {
      nextDropdown.current.focus();
    }

    const isTheSame = (addedFilters) =>
      JSON.stringify(addedFilters) === JSON.stringify(filtersToArray(filters));

    const lastHasntBeenSelected =
      addedFilters.length > 0 &&
      addedFilters[addedFilters.length - 1].value === undefined;

    const toCompare =
      waitingForSelected || lastHasntBeenSelected
        ? addedFilters.slice(0, -1)
        : addedFilters;

    if (!isTheSame(toCompare)) {
      filterSchools();
    }
  }, [addedFilters, filters, onFilter, waitingForSelected]);

  return (
    <div className={s.wrap}>
      <form ref={form}>
        <div className={s.options}>
          {addMetaToFilters.map(
            ({ colName, selected, prettyName, options, value }, i) => (
              <div key={colName} className={s.filterOption}>
                {colName === -1 && (
                  <FilterOptionBlank
                    text={text}
                    id={i}
                    reference={nextDropdown}
                    setSelectedColName={setSelectedColName}
                    filterableOptions={filterableOptionsWithoutAlreadySelected}
                    removeOption={removeOption}
                  />
                )}

                {colName !== -1 && (
                  <FilterOptionSelected
                    text={text}
                    id={i}
                    prettyName={prettyName}
                    colName={colName}
                    value={value}
                    setValue={setValue}
                    options={options}
                    reference={nextDropdown}
                    removeOption={removeOption}
                  />
                )}
              </div>
            )
          )}
          <div className={s.filterOption}>
            <Button
              disabled={waitingForSelected}
              onClick={(e) => {
                e.preventDefault();
                addOption();
              }}
              iconBefore="plus"
            >
              {text.addFilter}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterSchools;
