import React from "react";
import Button from "../Button";

const DownloadButton = ({ url, text = "Download" }) => {
  return (
    <div>
      <Button
        tag="a"
        iconBefore={"download"}
        href={`${process.env.REACT_APP_API_URL}${url}`}
      >
        {text}
      </Button>
    </div>
  );
};

export default DownloadButton;
