import React, { useState } from "react";
import s from "./styles.module.css";
import LineChart from "../LineChart";
import Note from "../Note";
import { getExamDetailsByKey } from "../../config";

const GradeOverview = ({ level, data, note }) => {
  const { minGrade, maxGrade, subjects, examName, name } = getExamDetailsByKey(
    level
  );
  const [gradeKey, setGradeKey] = useState(subjects[0].key);

  return (
    <div className={s.wrap}>
      <p>
        <strong>
          {examName} scores in {name} schools
        </strong>
      </p>

      <p>
        <select onChange={(e) => setGradeKey(e.target.value)}>
          {subjects.map(({ key, name }) => (
            <option value={key} key={key}>
              {name}
            </option>
          ))}
        </select>
      </p>

      <LineChart
        min={minGrade}
        max={maxGrade}
        cleanData={data[level][gradeKey]}
        hideYAxis
        // useBar
      />

      {note && <Note padded>{note}</Note>}
    </div>
  );
};
export default GradeOverview;
