import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const SelectionTab = ({
  categories,
  selectedCategory,
  setSelectedCategory
}) => {
  return (
    <div className={s.selectorBar}>
      {categories.map((category, index) => (
        <a
          href={`/school/${category.title}`}
          key={index}
          onClick={e => {
            e.preventDefault();
            setSelectedCategory(category.title);
          }}
          className={cn(
            s.selector,
            category.title === selectedCategory && s.active
          )}
        >
          {category.title}
        </a>
      ))}
    </div>
  );
};

export default SelectionTab;
