import React from "react";
import s from "./style.module.css";
import { scaleLinear } from "d3-scale";
import { line, area } from "d3-shape";
import cn from "classnames";
import roundTo from "../../helpers/roundTo";
import { generic as strings } from "../../strings";
import getRoundedUpValue from "../../helpers/getRoundedUpValue";
import Icon from "../Icon";

const LineChart = ({
  data,
  cleanData,
  title,
  min,
  max,
  extraTicks = [],
  suffix = "",
  hideYAxis,
  useBar,
  additionalData = [],
  color,
  note,
  modifier,
}) => {
  if (!data && !cleanData) {
    return <div className={s.noData}>{strings.noData}</div>;
  }

  const getData = (data) =>
    data.split(",").map((d) => {
      const [year, value] = d.split("|");

      let newValue = value === "" ? null : value;

      if (modifier && newValue !== null) {
        newValue = newValue / modifier;
      }

      return {
        year,
        value: newValue,
      };
    });
  cleanData = cleanData || getData(data || "");

  if (!max) {
    max = getRoundedUpValue(Math.max(...cleanData.map((d) => d.value)));
  }

  const minYear = Math.min(...cleanData.map(({ year }) => year));
  const maxYear = Math.max(...cleanData.map(({ year }) => year));

  const scaleY = scaleLinear().domain([min, max]).range([0, 100]);
  const scaleX = scaleLinear()
    .domain([minYear - 0.5, maxYear + 0.5])
    .range([0, 100]);

  const drawLine = line()
    .x((d) => scaleX(d.year))
    .y((d) => 100 - scaleY(d.value));

  const drawArea = area()
    .x((d) => scaleX(d.year))
    .y1((d) => 100 - scaleY(d.value))
    .y0(100);

  return (
    <div className={s.wrap}>
      <p>
        <strong>
          {title} {note && <Icon type="question" color="null" tooltip={note} />}
        </strong>
      </p>

      {cleanData.length === 0 && <div className={s.noData}>No data</div>}

      {cleanData.length > 0 && (
        <div className={cn(s.chart, { [s.hideYAxis]: hideYAxis })}>
          {!hideYAxis && (
            <div className={s.xTicks}>
              <span className={s.min}>
                {min}
                {suffix}
              </span>

              {extraTicks.map((tick) => (
                <span
                  key={tick}
                  className={s.tick}
                  style={{
                    "--y": scaleY(tick),
                  }}
                >
                  {tick}
                  {suffix}
                </span>
              ))}

              <span className={s.max}>
                {max}
                {suffix}
              </span>
            </div>
          )}

          <div className={s.chartInner}>
            {!useBar && (
              <svg
                className={s.svg}
                viewBox={"0 0 100 100"}
                preserveAspectRatio="none"
              >
                {additionalData.map(({ data, color }, i) => (
                  <path
                    key={i}
                    d={drawArea(data)}
                    stroke={color}
                    strokeWidth={1}
                    fillOpacity={0}
                  />
                ))}

                <path
                  d={drawLine(cleanData.filter(({ value }) => value !== null))}
                  stroke={color || "currentColor"}
                  strokeWidth={1}
                  fill={color}
                  fillOpacity={0}
                />

                <path
                  d={drawArea(cleanData.filter(({ value }) => value !== null))}
                  stroke={color || "currentColor"}
                  strokeWidth={0}
                  fill={color || "currentColor"}
                  fillOpacity={0.1}
                />
              </svg>
            )}

            {additionalData.map(({ data, color }, i) => (
              <div key={i}>
                {data.map(({ year, value }) => (
                  <div
                    key={year}
                    className={cn(s.point, { [s.isBar]: useBar })}
                    style={{
                      color,
                      "--x": scaleX(year),
                      "--y": scaleY(value),
                    }}
                  >
                    {false && (
                      <span className={s.value}>
                        {roundTo(value, 1)}
                        {suffix}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ))}

            {cleanData.map(({ year, value }, i) => (
              <div
                key={year}
                className={cn(s.point, {
                  [s.isBar]: useBar,
                  [s.isNull]: value === null,
                })}
                style={{
                  color,
                  "--x": scaleX(year),
                  "--y": value === null ? 0 : scaleY(value),
                }}
              >
                {value !== null && (
                  <span
                    className={cn(s.value, {
                      [s.isLast]: i === cleanData.length - 1,
                    })}
                  >
                    {roundTo(value, 1)}
                    {suffix}
                  </span>
                )}
                <span
                  className={cn(s.year, { [s.yearHasNoData]: value === null })}
                >
                  {year}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LineChart;
