import React from "react";
import s from "./style.module.css";
import Label from "../Label";

const SpacingScale = ({ sizes = [] }) => (
  <div>
    {Object.entries(sizes.values).map(([key, value]) => (
      <p>
        <span
          style={{
            display: "block",
            width: `${value}`
          }}
        >
          <span className={s.block} />
        </span>
        <Label>
          {sizes.prefix}-{key}
        </Label>
      </p>
    ))}
  </div>
);

export default SpacingScale;
