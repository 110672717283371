import React from "react";
import s from "./style.module.css";
import { formatOption } from "../../helpers/meta";
import ContinuousLegend from "../MetricCharts/ContinuousLegend";
import Box from "../Box";
import cn from "classnames";

const MapLegend = ({ selected, colors, options, className }) => {
  const min = options[0];
  const max = options[options.length - 1];

  return (
    <Box
      tight
      hasBoxShadow
      className={className}
      header={
        <p className={s.title}>
          <strong>{selected.prettyNameDropdown}</strong>
        </p>
      }
    >
      {selected.dataType === "Numerical" && (
        <div className={s.continuousLegend}>
          <ContinuousLegend
            faded
            numberOfOptions={options.length}
            min={formatOption(min, false, true)}
            max={formatOption(max, true)}
          />
        </div>
      )}

      {selected.dataType === "Categorical" && (
        <div className={cn(s.categories, { [s.split]: colors.length > 10 })}>
          {colors.map((color, i) => (
            <div
              key={i}
              className={s.legendItem}
              style={{
                "--color": color
              }}
            >
              {formatOption(options[i])}
            </div>
          ))}
        </div>
      )}
    </Box>
  );
};

export default MapLegend;
