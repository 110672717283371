export const type = {
  name: "Type",
  prefix: "f",
  values: {
    default:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"'
  }
};

export const spacing = {
  name: "Spacing",
  prefix: "s",
  values: {
    xs: "4px",
    s: "8px",
    m: "16px",
    l: "32px",
    xl: "64px",
    xxl: "128px"
  }
};

export const spacingNegative = {
  name: "Spacing negative",
  prefix: "s-minus",
  values: {
    xs: "-4px",
    s: "-8px",
    m: "-16px",
    l: "-32px",
    xl: "-64px",
    xxl: "-128px"
  }
};

export const colors = {
  name: "Colors",
  prefix: "c",
  values: {
    white: "#fff",
    offWhite: "#fafafa",
    black: "#222230",
    lightGrey: "#d4d4e2",
    grey: "#76808D",
    darkGrey: "#4f5a65",
    blue: "#3455DB",
    midBlue: "#00008B",
    darkBlue: "#17172C",
    green: "#00AA55",
    yellow: "#F7EE0E",
    red: "#FF005D",
    orange: "#FF4500"
  }
};

export const typeSizes = {
  name: "Type sizes",
  prefix: "fs",
  values: {
    xs: "12px",
    s: "14px",
    m: "16px",
    l: "20px",
    xl: "24px",
    xxl: "32px"
  }
};

export const letterSpacings = {
  name: "Letter spacing",
  prefix: "ls",
  values: {
    xs: "0.15px",
    s: "0.25px",
    m: "0.4px",
    l: "1.25px"
  }
};

export const weights = {
  name: "Font weights",
  prefix: "fw",
  values: {
    regular: "regular",
    bold: "bold"
  }
};

export const iconSizing = {
  name: "Icon sizing",
  prefix: "is",
  values: {
    sm: "14px",
    md: "20px"
  }
};

export default {
  type,
  spacing,
  spacingNegative,
  colors,
  typeSizes,
  letterSpacings,
  weights,
  iconSizing
};
