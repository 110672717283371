import React from "react";
import s from "./style.module.css";
import Label from "../Label";

const LetterSpacingScale = ({
  sizes = [],
  text = "The quick brown fox jumps over the lazy dog"
}) => (
  <div>
    {Object.entries(sizes.values).map(([key, value]) => (
      <p className={s.wrap}>
        <span
          style={{
            letterSpacing: value
          }}
        >
          {text}
        </span>
        <Label>
          {sizes.prefix}-{key}
        </Label>
      </p>
    ))}
  </div>
);

export default LetterSpacingScale;
