import React, { Component } from "react";
import PassRate from "../PassRate";
import Button from "../Button";
import Loader from "../Loader";
import Approval from "../Approval";
import { withRouter } from "react-router-dom";
import s from "./styles.module.css";
import { schoolsIndex } from "../../strings";
import { sortData } from "../../helpers/dataTasks";
import ReactProgressiveList from "react-progressive-list";
import { Link } from "react-router-dom";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "idschool_name",
      sortASC: true
    };
    this.sort = this.sort.bind(this);
  }

  sort({ sortBy }) {
    const sortASC =
      this.state.sortBy === sortBy ? !this.state.sortASC : this.state.sortASC;

    this.setState({
      sortBy: sortBy,
      sortASC: sortASC
    });
  }

  render() {
    const { data, passRateBoundary, isLoading } = this.props;
    const { sortBy, sortASC } = this.state;
    const sortedFilteredData = sortData(data, sortBy, sortASC);

    return (
      <div className={s.tableWrap}>
        <Loader isInternal isVisible={isLoading} />

        <div className={s.header}>
          <p className={s.schoolName}>{schoolsIndex.table.schoolName}</p>
          <p className={s.approval}>{schoolsIndex.table.approval}</p>
          <p>{schoolsIndex.table.educationLevel}</p>
          <p>{schoolsIndex.table.district}</p>
          <p className={s.passRate}>{schoolsIndex.table.passRate}</p>
          <p className={s.button} />
        </div>

        {sortedFilteredData.length === 0 && (
          <div className={s.noSchools}>{schoolsIndex.table.noSchools}</div>
        )}

        {sortedFilteredData.length > 0 && (
          <ReactProgressiveList
            initialAmount={Math.min(100, sortedFilteredData.length)}
            progressiveAmount={100}
            renderItem={i => {
              const {
                id,
                idschool_name,
                pass_rate,
                sch_type,
                approval_status,
                iddistrict
              } = sortedFilteredData[i];
              return (
                <Link
                  key={id}
                  to={`/schools/${id}`}
                  className={s.school}
                >
                  <p className={s.schoolName}> {idschool_name}</p>
                  <p className={s.approval}>
                    <Approval hideText isAfter status={approval_status} />
                  </p>
                  <p className={s.schoolType}>{sch_type}</p>
                  <p className={s.schoolDistrict}>{iddistrict}</p>

                  <div className={s.passRate}>
                    <PassRate
                      passRate={pass_rate}
                      passRateBoundary={passRateBoundary}
                    />
                  </div>

                  <p className={s.button}>
                    <Button iconAfter={"arrowRight"} secondary>
                      {schoolsIndex.table.buttonText}
                    </Button>
                  </p>
                </Link>
              );
            }}
            rowCount={sortedFilteredData.length}
            useWindowScroll
          />
        )}
      </div>
    );
  }
}

export default withRouter(DataTable);
