import React from "react";
import cn from "classnames";
import s from "./styles.module.css";
import { scaleLinear } from "d3-scale";
import roundTo from "../../../helpers/roundTo";

const Axis = ({
  min,
  max,
  suffix = "",
  ticks = [],
  includeMid,
  mid,
  midText,
  noRounding,
}) => {
  const x = scaleLinear().domain([min, max]).range([0, 1]);

  if (includeMid) {
    ticks = [{ value: mid, text: midText }];
  }

  return (
    <div className={cn(s.axis, { [s.hasExtraTicks]: ticks.length })}>
      <span className={s.tick}>
        <span className={s.tickInner}>
          {noRounding ? min : roundTo(min)}
          {suffix}
        </span>
      </span>
      {ticks.map(({ value, text }, index) => (
        <span
          key={index}
          className={s.extraTick}
          style={{ "--position": x(value) }}
        >
          {text}
          <span>({roundTo(value, 1)})</span>
        </span>
      ))}
      <span className={s.tick}>
        <span className={cn(s.tickInner, s.tickLargest)}>
          {noRounding ? max : roundTo(max)}
          {suffix}
        </span>
      </span>
    </div>
  );
};

export default Axis;
