import React from "react";
import s from "./styles.module.css";
import cn from "classnames";
import { scaleLinear } from "d3-scale";

const BarChart = ({ value, min, max, suffix, color, simple = true }) => {
  const x = scaleLinear()
    .domain([min, max])
    .range([0, 1]);

  const width = x(value);

  return (
    <div className={cn(s.chart, { [s.simple]: simple })}>
      <div className={s.blockWrap}>
        <div
          className={cn(s.block, { [s.notZero]: value !== 0 })}
          style={{
            color: color,
            "--width": width
          }}
        >
          <div className={cn(s.value, { [s.valueInside]: width > 0.5 })}>
            {value}
            {suffix}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
