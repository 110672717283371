import React, { useState } from "react";
import Icon from "../Icon";
import cn from "classnames";
import s from "./style.module.css";

const SearchBar = ({ handleSearch, placeholderText }) => {
  const [isActive, setActive] = useState(false);
  return (
    <div className={cn(s.inputWrapper, isActive && s.active)}>
      <Icon type={"search"} active={isActive} />
      <input
        className={s.input}
        onChange={e => handleSearch(e.target.value)}
        placeholder={placeholderText}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      />
    </div>
  );
};

export default SearchBar;
