import React from "react";
import s from "./styles.module.css";
import cn from "classnames";
import { scaleLinear } from "d3-scale";
import { generic as strings } from "../../strings";

const PercentageBar = ({ value, min = 0, max = 100, suffix, color }) => {
  const x = scaleLinear()
    .domain([min, max])
    .range([0, 1]);

  const width = x(value);

  const noData = value === null;

  return (
    <div
      className={cn(s.chart, {
        [s.positive]: value >= 50,
        [s.negative]: value < 50
      })}
    >
      {noData && <div className={s.noData}>{strings.noData}</div>}

      {!noData && (
        <div className={s.blockWrap}>
          <div
            className={cn(s.block, { [s.notZero]: value !== 0 })}
            style={{
              "--width": width
            }}
          >
            <div className={cn(s.value, { [s.valueInside]: width > 0.5 })}>
              {value}
              {suffix}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PercentageBar;
