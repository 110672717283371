import React, { useState } from "react";
import cn from "classnames";
import s from "./style.module.css";
import Button from "../Button";
import LazyLoad from "react-lazy-load";

const ImageGallery = ({ images = [] }) => {
  const [active, setActive] = useState(0);

  const next = () => {
    const next = active + 1 === images.length ? 0 : active + 1;
    setActive(next);
  };

  const previous = () => {
    const previous = active - 1 < 0 ? images.length - 1 : active - 1;
    setActive(previous);
  };

  return (
    <div className={s.wrap}>
      <div className={s.images}>
        <div>
          {images.map(({ id, text, url }, i) => (
            <div
              className={cn(s.imgWrap, { [s.active]: active === i })}
              key={i}
            >
              <LazyLoad>
                <img
                  className={s.img}
                  alt={text}
                  src={`${url}?auto=compress&fit=clip&w=1024`}
                />
              </LazyLoad>
            </div>
          ))}
        </div>
        {images.length === 0 && <p className={s.noImages}>No images</p>}

        {images.length > 1 && (
          <div className={s.buttons}>
            <Button iconBefore="arrowLeft" onClick={previous} />
            <Button onClick={next} iconBefore="arrowRight" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
