import React, { useState } from "react";
import s from "./styles.module.css";
import Page from "../../components/Page";
import MetricCompare from "../../components/MetricCompare";
import Button from "../../components/Button";
import Box from "../../components/Box";
import { compareStats } from "../../helpers/api";
import stringReplace from "../../helpers/stringReplace";
import { compare, chartTypes } from "../../strings";
import MetricDropDown from "../../components/MetricDropDown";
import { getExamDetailsByKey, exams } from "../../config";
import useAPI from "../../helpers/useAPI";

const MetricComparison = props => {
  const [educationLevel, setEducationLevel] = useState("primary");
  const [metricThree, setMetricThree] = useState("shift_status");
  const [metricTwo, setMetricTwo] = useState("approval_status");
  const [chartType, setChartType] = useState("percentageChange");

  const { data, meta, isLoading, hasError } = useAPI(
    compareStats,
    educationLevel,
    metricTwo,
    metricThree
  );

  const switchMetrics = () => {
    setMetricTwo(metricThree);
    setMetricThree(metricTwo);
  };

  const { examName } = getExamDetailsByKey(educationLevel);

  return (
    <Page title={compare.title} isLoading={isLoading} hasError={hasError}>
      <div>
        <Box>
          <div className={s.header}>
            <div>
              <label>{compare.options.educationLevel}</label>
              <select
                onChange={e => setEducationLevel(e.target.value)}
                value={educationLevel}
              >
                <option value="primary">{exams.primary.name}</option>
                <option value="junior">{exams.junior.name}</option>
                <option value="senior">{exams.senior.name}</option>
              </select>
            </div>

            <div />

            <div>
              <label>{compare.options.chartType}</label>
              <select
                onChange={e => setChartType(e.target.value)}
                value={chartType}
              >
                {Object.entries(chartTypes).map(([key, name], i) => (
                  <option key={key} value={key}>
                    {stringReplace(name, { $examName: examName })}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className={s.optionTitle} /> */}

            <div>
              <label>{compare.options.chooseMetricOne}</label>
              <MetricDropDown
                options={meta}
                onChange={e => {
                  setMetricTwo(e.target.value);
                }}
                value={metricTwo}
                level={educationLevel}
              />
            </div>

            <div className={s.switcher}>
              <Button
                onClick={switchMetrics}
                iconBefore={"arrowSwitch"}
                secondary
                tooltip={compare.options.switchTitle}
              />
            </div>

            <div>
              <label>{compare.options.chooseMetricTwo}</label>
              <MetricDropDown
                options={meta}
                onChange={e => {
                  setMetricThree(e.target.value);
                }}
                value={metricThree}
              />
            </div>
          </div>
        </Box>

        {data && (
          <MetricCompare
            {...data}
            level={educationLevel}
            chartType={chartType}
          />
        )}
      </div>
    </Page>
  );
};

export default MetricComparison;
