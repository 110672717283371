import React from "react";
import Box from "../../components/Box";
import BoxInner from "../../components/BoxInner";
import LineChart from "../../components/LineChart";
import PassRate from "../../components/PassRate";
// import s from "./style.module.css";
import { school } from "../../strings";
import { getExamDetailsByName } from "../../config";

const ResultsByTime = ({ data }) => {
  const {
    min,
    max,
    extraTicks,
    resultsKey,
    examName,
    shortAbout,
    passRateExplanation,
  } = getExamDetailsByName(data.sch_type);

  const results = data[`${resultsKey}_year`];
  const passRate = data.pass_rate_year;

  return (
    <Box
      title={school.boxTitles.exams}
      additionalHeaderInfo={
        <PassRate passRate={data.pass_rate} iconPosition="right" includeText />
      }
      highlight={
        data.pass_rate
          ? data.pass_rate >= 50
            ? "positive"
            : "negative"
          : "noData"
      }
    >
      {!passRate && <p>No data</p>}

      {passRate && (
        <BoxInner columns={3}>
          <LineChart
            data={data.sat_year}
            min={0}
            useBar
            title={school.performance.studentsSat.replace(
              "$examName",
              examName
            )}
          />
          <LineChart
            data={passRate}
            min={0}
            max={100}
            suffix={"%"}
            extraTicks={[25, 50, 75]}
            title={school.performance.passRate.replace("$examName", examName)}
            note={passRateExplanation}
          />
          <LineChart
            data={results}
            min={min}
            max={max}
            extraTicks={extraTicks}
            useBar
            title={school.performance.marks
              .replace("$examName", examName)
              .replace(
                "$scoreGrade",
                resultsKey === "avg_score_p" ? "score" : "grade"
              )}
            modifier={
              {
                avg_score_p: 1,
                avg_score_ss: 1,
                avg_score_js: 1,
              }[resultsKey]
            }
            note={shortAbout}
          />
        </BoxInner>
      )}
    </Box>
  );
};
export default ResultsByTime;
