import React from "react";
import Icon from "../Icon";
import s from "./style.module.css";

const Approval = ({ status, isAfter, hideText }) => {
  const options = {
    approved: {
      icon: "tick",
      highlight: "positive",
    },
    "not approved": {
      icon: "cross",
      highlight: "negative",
    },
    "applied for approval": {
      icon: "question",
      highlight: "neutral",
    },
  };

  const { icon, highlight } = options[status.toLowerCase()];

  return (
    <span>
      {!isAfter && !hideText && <span className={s.text}>{status}</span>}
      <Icon tooltip={hideText && status} type={icon} color={highlight} />
      {isAfter && !hideText && <span className={s.text}>{status}</span>}
    </span>
  );
};

export default Approval;
