import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const Button = ({
  tag: Tag = "button",
  children,
  secondary,
  className,
  forceActive,
  iconBefore,
  iconAfter,
  tooltip,
  ...rest
}) => {
  return (
    <Tag
      className={cn(s.button, {
        [className]: className,
        [s.forceActive]: forceActive,
        [s.empty]: !children,
        [s.secondary]: secondary
      })}
      {...rest}
    >
      {tooltip && <Tooltip top>{tooltip}</Tooltip>}
      <span className={s.inner}>
        {iconBefore && (
          <span className={s.icon}>
            <Icon size="sm" type={iconBefore} />
          </span>
        )}
        {children && <span>{children}</span>}
        {iconAfter && (
          <span className={s.icon}>
            <Icon size="sm" type={iconAfter} />
          </span>
        )}
      </span>
    </Tag>
  );
};

export default Button;
