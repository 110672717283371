import React from "react";
import s from "./style.module.css";
import Icon from "../Icon";
import cn from "classnames";
import { generic as strings } from "../../strings";
import PercentageBar from "../PercentageBar";

const PassRate = ({
  passRate,
  passRateBoundary = 50,
  iconPosition = "left",
  toggleBar,
  includeText
}) => {
  const text = passRate !== null ? `${passRate}%` : strings.noData;

  const icon =
    passRate >= passRateBoundary ? (
      <Icon type={"tick"} color="positive" />
    ) : passRate === null ? (
      <Icon type="noData" color="null" />
    ) : (
      <Icon type={"cross"} color="negative" />
    );

  return (
    <div>
      {!toggleBar && (
        <div>
          {iconPosition === "left" && icon}
          {includeText && <span className={s.text}>Pass rate:</span>}
          <span className={cn(s.passRate, { [s.noData]: passRate === null })}>
            {text}
          </span>
          {iconPosition === "right" && icon}
        </div>
      )}

      {toggleBar && <PercentageBar suffix={"%"} value={passRate} />}
    </div>
  );
};

export default PassRate;
