import React, { useState } from "react";
import s from "./styles.module.css";
import Page from "../../components/Page";
import MetricViz from "../../components/MetricViz";
import Box from "../../components/Box";
import { getStats } from "../../helpers/api";
import { explore } from "../../strings";
import MetricDropDown from "../../components/MetricDropDown";
import useAPI from "../../helpers/useAPI";
import { exams } from "../../config";

const Explorer = props => {
  const [educationLevel, setEducationLevel] = useState("primary");
  const [metricTwo, setMetricTwo] = useState("approval_status");

  const { data, meta, isLoading, hasError } = useAPI(
    getStats,
    educationLevel,
    metricTwo
  );

  return (
    <Page title={explore.title} isLoading={isLoading} hasError={hasError}>
      <div>
        <Box>
          <div className={s.header}>
            <div>
              <label>{explore.options.educationLevel}</label>
              <select
                onChange={e => setEducationLevel(e.target.value)}
                value={educationLevel}
              >
                <option value="primary">{exams.primary.name}</option>
                <option value="junior">{exams.junior.name}</option>
                <option value="senior">{exams.senior.name}</option>
              </select>
            </div>

            <div>
              <label>{explore.options.chooseMetric}</label>
              <MetricDropDown
                options={meta}
                onChange={e => setMetricTwo(e.target.value)}
                value={metricTwo}
                level={educationLevel}
              />
            </div>
          </div>
        </Box>

        {data && <MetricViz {...data} level={educationLevel} />}
      </div>
    </Page>
  );
};

export default Explorer;
