import React from "react";
import s from "./styles.module.css";
import cn from "classnames";

const Tooltip = ({ metrics = [], children, top, darkenArea }, index) => (
  <span key={index} className={cn(s.wrap, { [s.darkenArea]: darkenArea })}>
    <span className={cn(s.toolTip, { [s.top]: top })}>
      {metrics.map(({ name, value }, index) => (
        <span key={index} className={s.metric}>
          {name}: <strong>{value}</strong>
        </span>
      ))}
      {children && children}
    </span>
  </span>
);

export default Tooltip;
