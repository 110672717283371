import React from "react";
import s from "./style.module.css";
import Label from "../Label";

const Colors = ({ colors = [] }) => (
  <div className={s.wrap}>
    {Object.entries(colors.values).map(([key, value]) => (
      <p>
        <span
          className={s.block}
          style={{
            color: value
          }}
        />
        <Label>{key}</Label>
      </p>
    ))}
  </div>
);

export default Colors;
