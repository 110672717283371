import React from "react";
import s from "./styles.module.css";
import DistributionChart from "../MetricCharts/DistributionChart";
import PercentageChange from "../MetricCharts/PercentageChange";
import BarChart from "../MetricCharts/BarChart";
import Axis from "../MetricCharts/Axis";
import Box from "../Box";
import SourcesAndNotes from "../SourcesAndNotes";
import getRoundedUpValue from "../../helpers/getRoundedUpValue";
import getMinMaxForChange from "../../helpers/getMinMaxForChange";
import { explore, chartTypes } from "../../strings";
import { getExamDetailsByKey } from "../../config";
import stringReplace from "../../helpers/stringReplace";

const MetricViz = ({
  sentenceBuilder,
  countOfSchoolsWithMissingData,
  countOfMissingSchools,
  numberOfSchoolsMax,
  options,
  maxCountPerResult,
  noDataForQuestion,
  isNumerical,
  sampleMean,
  level,
}) => {
  const exam = getExamDetailsByKey(level);
  const roundedNumberOfSchoolsMax = getRoundedUpValue(numberOfSchoolsMax);
  const { min, max, size } = getMinMaxForChange(options);
  const changeMin = sampleMean * (exam.min > exam.max ? max : min);
  const changeMax = sampleMean * (exam.min > exam.max ? min : max);

  return (
    <Box
      header={
        <div className={s.vizHeader}>
          <h2>
            {stringReplace(explore.metricTitle, {
              $examName: exam.name,
              $sentenceBuilder: sentenceBuilder,
            })}
          </h2>
        </div>
      }
    >
      {!noDataForQuestion && (
        <div className={s.grid}>
          <div className={s.header}>
            <div />
            <div className={s.axisTitle}>
              {stringReplace(chartTypes.numberOfSchools, {
                $examName: exam.examName,
              })}
            </div>
            <div className={s.axisTitle}>
              {stringReplace(chartTypes.distribution, {
                $examName: exam.examName,
              })}
            </div>
            <div className={s.axisTitle}>
              {stringReplace(chartTypes.percentageChange, {
                $examName: exam.examName,
              })}
            </div>
          </div>

          {options.map(
            ({
              name,
              changeFromSampleMean,
              countPerResult,
              singleName,
              hoverName,
              count,
              ...box
            }) => (
              <div key={name} className={s.option}>
                <div className={s.optionName}>{singleName}</div>

                <BarChart
                  value={count}
                  min={0}
                  max={roundedNumberOfSchoolsMax}
                />

                <div className={s.heatmap}>
                  <DistributionChart
                    data={countPerResult}
                    min={exam.min}
                    max={exam.max}
                    maxSchools={maxCountPerResult}
                    changeLineMin={changeMin}
                    changeLineMax={changeMax}
                    sampleMean={sampleMean}
                    indicator={hoverName}
                    indicatorOneIsNumeric={box.isNumerical}
                    showSampleBox
                    rounding={level !== "primary" ? 1 : 0}
                  />
                </div>

                <PercentageChange
                  score={box.mean}
                  change={changeFromSampleMean}
                  min={size}
                  max={-size}
                  isBackwards={exam.min > exam.max}
                />
              </div>
            )
          )}

          <div className={s.footer}>
            <div />

            <div>
              <Axis min={0} max={roundedNumberOfSchoolsMax} />
            </div>
            <div>
              <Axis
                min={exam.min}
                max={exam.max}
                ticks={[{ value: exam.passMark, text: "Pass mark" }]}
              />
            </div>
            <div>
              <Axis
                min={changeMin}
                max={changeMax}
                includeMid
                suffix={""}
                mid={sampleMean}
                midText={"Average score"}
              />
            </div>
          </div>
        </div>
      )}

      {!noDataForQuestion && (
        <p className={s.missingData}>
          {countOfSchoolsWithMissingData > 0 && (
            <span>
              {explore.footer.replace(
                "$schools",
                countOfSchoolsWithMissingData
              )}
            </span>
          )}
        </p>
      )}

      {noDataForQuestion && <p>No data available</p>}

      <SourcesAndNotes
        type={level}
        numberOfMissingSchools={countOfMissingSchools}
      />
    </Box>
  );
};

export default MetricViz;
