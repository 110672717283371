import React from "react";
import s from "./style.module.css";
import Icon from "../../../components/Icon";

const Icons = ({ icons, ...rest }) => {
  return (
    <div className={s.wrap}>
      {icons.map(icon => (
        <div className={s.icon}>
          <Icon type={icon} {...rest} />
        </div>
      ))}
    </div>
  );
};

export default Icons;
