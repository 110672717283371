import React from "react";
import s from "./style.module.css";
import { titleToHash } from "../helpers";

const Section = ({ children, title = "" }) => (
  <div className={s.wrap} id={titleToHash(title)}>
    <h2 className={s.children}>{title}</h2>
    <div>{children}</div>
  </div>
);

export default Section;
