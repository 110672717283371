import React from "react";
import Icon from "../Icon";
import s from "./style.module.css";
import cn from "classnames";

const WarningBox = ({ message, full }) => {
  return (
    <div className={cn(s.message, { [s.full]: full })}>
      <Icon type="exclamation" color="warning" />
      <span className={s.messageContent}>{message}</span>
    </div>
  );
};

export default WarningBox;
