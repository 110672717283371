import React from "react";
import s from "./style.module.css";
import Label from "../Label";
import Box from "../../../components/Box";
import BoxInner from "../../../components/BoxInner";

const Boxes = ({ ...rest }) => {
  return (
    <div className={s.wrap}>
      <div className={s.box}>
        <Box>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et
            quam ex. Donec nec orci scelerisque leo porta eleifend.
          </p>
          <p>
            {" "}
            Maecenas purus lectus, blandit hendrerit tortor at, venenatis
            vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
            risus.{" "}
          </p>
        </Box>
        <Label>Simple container</Label>
      </div>

      <div className={s.box}>
        <Box title="Container title">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et
            quam ex. Donec nec orci scelerisque leo porta eleifend.
          </p>
          <p>
            {" "}
            Maecenas purus lectus, blandit hendrerit tortor at, venenatis
            vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
            risus.{" "}
          </p>
        </Box>
        <Label>Container with title</Label>
      </div>

      <div className={s.box}>
        <Box
          title="Container title"
          additionalHeaderInfo={<p>Additional header info</p>}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et
            quam ex. Donec nec orci scelerisque leo porta eleifend.
          </p>
          <p>
            {" "}
            Maecenas purus lectus, blandit hendrerit tortor at, venenatis
            vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
            risus.{" "}
          </p>
        </Box>
        <Label>Container with title and extra content</Label>
      </div>

      <div className={s.box}>
        <Box tight>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et
            quam ex. Donec nec orci scelerisque leo porta eleifend.
          </p>
          <p>
            {" "}
            Maecenas purus lectus, blandit hendrerit tortor at, venenatis
            vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
            risus.{" "}
          </p>
        </Box>
        <Label>Container with tight padding</Label>
      </div>

      <div className={s.box}>
        <Box tight title="Container title">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et
            quam ex. Donec nec orci scelerisque leo porta eleifend.
          </p>
          <p>
            {" "}
            Maecenas purus lectus, blandit hendrerit tortor at, venenatis
            vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
            risus.{" "}
          </p>
        </Box>
        <Label>Container with tight padding and header</Label>
      </div>

      <div className={s.box}>
        <Box title="Container title">
          <BoxInner>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              et quam ex. Donec nec orci scelerisque leo porta eleifend.
            </p>
            <p>
              {" "}
              Maecenas purus lectus, blandit hendrerit tortor at, venenatis
              vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
              risus.{" "}
            </p>
          </BoxInner>
        </Box>
        <Label>Container with two columns</Label>
      </div>

      <div className={s.box}>
        <Box>
          <BoxInner>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              et quam ex. Donec nec orci scelerisque leo porta eleifend.
            </p>
            <p>
              {" "}
              Maecenas purus lectus, blandit hendrerit tortor at, venenatis
              vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
              risus.{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              et quam ex. Donec nec orci scelerisque leo porta eleifend.
            </p>
          </BoxInner>
        </Box>
        <Label>Container with two columns and extra content</Label>
      </div>

      <div className={s.box}>
        <Box title="Container title">
          <BoxInner columns={3}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              et quam ex. Donec nec orci scelerisque leo porta eleifend.
            </p>
            <p>
              {" "}
              Maecenas purus lectus, blandit hendrerit tortor at, venenatis
              vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
              risus.{" "}
            </p>
            <p>
              {" "}
              Maecenas purus lectus, blandit hendrerit tortor at, venenatis
              vehicula nibh. Proin at enim consectetur, ornare orci a, ultrices
              risus.{" "}
            </p>
          </BoxInner>
        </Box>
        <Label>Container with three columns</Label>
      </div>
    </div>
  );
};

export default Boxes;
