import React from "react";
import Label from "../Label";

const FontScale = () => (
  <div>
    <p>
      <input /> <Label>Input</Label>
    </p>
    <p>
      <textarea />
      <Label>Textarea</Label>
    </p>
    <p>
      <input name="test" type="radio" checked />
      <input name="test" type="radio" />
      <Label>Radio</Label>
    </p>
    <p>
      <input type="checkbox" />
      <Label>Checkbox</Label>
    </p>
    <p>
      <select>
        <option>Option 1</option>
        <option>Option 2</option>
        <option>Option 3</option>
      </select>
      <Label>Select</Label>
    </p>

    <p>
      <select>
        <optgroup label="Option group 1">
          <option>Option 1</option>
          <option>Option 2</option>
        </optgroup>
        <optgroup label="Option group 2">
          <option>Option 3</option>
        </optgroup>
      </select>
      <Label>Select with optgroup</Label>
    </p>
  </div>
);

export default FontScale;
