import React from "react";
import s from "./styles.module.css";
import Axis from "../Axis";
import swatches from "../../../helpers/swatches";
import cn from "classnames";

const ContinuousLegend = ({
  min,
  max,
  numberOfOptions,
  suffix,
  title,
  faded
}) => {
  const colors = swatches[numberOfOptions - 1];

  const coloursWithSteps = colors.map(
    (color, i, arr) =>
      `${color} ${(i / arr.length) * 100}%, ${color} ${((i + 1) / arr.length) *
        100}%`
  );

  return (
    <div className={cn(s.legend, { [s.faded]: faded })}>
      <div className={s.title}>{title}</div>
      <div className={s.wrap}>
        <div
          className={s.bar}
          style={{
            background: `linear-gradient(90deg, ${coloursWithSteps.join(",")})`
          }}
        />
        <Axis noRounding min={min} max={max} suffix={suffix} />
      </div>
    </div>
  );
};

export default ContinuousLegend;

// var(--t-subtle);
// rgb(8, 48, 107)
