const getFromAPI = (path, method = "GET", body) => {
  const options = {
    method,
    headers: {},
  };

  if (method === "POST" && body) {
    options.body = JSON.stringify(body);
    options.headers["Content-type"] = "application/json";
  }

  return fetch(`${process.env.REACT_APP_API_URL}/${path}`, options)
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res;
    })
    .then((res) => res.json())
    .catch((err) => {
      throw Error(err);
    });
};

const getMeta = (name) =>
  fetch(`/api/${name}.json`)
    .then((res) => res.json())
    .catch(console.log);

export const getSchools = () =>
  getFromAPI(`schools/${process.env.REACT_APP_DATA_YEAR}`);

export const getSchool = (id) =>
  getFromAPI(`schools/${process.env.REACT_APP_DATA_YEAR}/${id}`);

export const getFilteredSchools = (filters) =>
  getFromAPI(`schools/${process.env.REACT_APP_DATA_YEAR}`, "POST", filters);

export const getSchoolAsCSV = (id) =>
  getFromAPI(`downloads/${process.env.REACT_APP_DATA_YEAR}/school/${id}`);

export const getMetaInfo = () => getMeta("meta");

export const getMappedResults = (type, id) =>
  getFromAPI(`indicators/${process.env.REACT_APP_DATA_YEAR}/${type}/${id}`);

export const getStats = (type, id) =>
  getFromAPI(`stats/${process.env.REACT_APP_DATA_YEAR}/${type}/${id}`);

export const compareStats = (type, id, idTwo) =>
  getFromAPI(`stats/${process.env.REACT_APP_DATA_YEAR}/${type}/${id}/${idTwo}`);

export const getOverview = () =>
  getFromAPI(`overview/${process.env.REACT_APP_DATA_YEAR}`);
