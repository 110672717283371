import meta from "../api/data/meta.json";
import { useEffect, useState } from "react";

const useAPI = (method, propertyOne, propertyTwo, propertyThree) => {
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);

    method(propertyOne, propertyTwo, propertyThree)
      .then(setData)
      .then(() => setIsLoading(false))
      .catch((err) => {
        setIsLoading(false);
        setHasError(true);
      });
  }, [method, propertyOne, propertyTwo, propertyThree]);

  return {
    data,
    meta,
    isLoading,
    hasError,
  };
};

export default useAPI;
