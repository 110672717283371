import React, { useState } from "react";
import s from "./style.module.css";
import cn from "classnames";

import { getMetaByCategories } from "../../helpers/meta";
import Box from "../Box";
import SelectionTab from "../SelectionTab";
import DataCard from "../DataCard";
import { school } from "../../strings";

const levelLookup = {
  "pre-primary": "preprimary",
  primary: "primary",
  "junior secondary": "junior",
  "senior secondary": "senior"
};

const CatergorySelector = ({ data, meta }) => {
  const [selectedCategory, setSelectedCategory] = useState("Profile");

  const metaToShow = meta.filter(d => d.statistics_show);

  const categorisedMeta = getMetaByCategories(
    metaToShow,
    null,
    levelLookup[data.sch_type.toLowerCase()],
    true
  )
    .map(category => {
      return {
        ...category,
        categories: getMetaByCategories(
          category.data,
          null,
          levelLookup[data.sch_type.toLowerCase()]
        )
      };
    })
    .filter(({ data }) => data.length);

  return (
    <Box title={school.boxTitles.statistics} last>
      <div className={s.categoryWrapper}>
        <div className={s.selector}>
          <SelectionTab
            categories={categorisedMeta}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div>
        {categorisedMeta.map((category, index) => (
          <div
            key={index}
            className={cn(
              s.dataContainer,
              category.title === selectedCategory && s.columnsActive
            )}
          >
            {category.categories.map((field, index) => (
              <div key={field.title} className={s.category}>
                <h4 className={s.title}>{field.title}</h4>
                <div className={s.columns}>
                  {field.data.map((field, index) => (
                    <DataCard data={data} fieldToDisplay={field} key={index} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default CatergorySelector;
