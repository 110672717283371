import React from "react";
import s from "./styles.module.css";
import BarChart from "../MetricCharts/BarChart";
import roundTo from "../../helpers/roundTo";

const IndicatorOverview = ({
  title,
  colName,
  year,
  counts,
  roundedMaxNumberOfSchools,
  description,
  text = {},
}) => {
  return (
    <div>
      <p>
        <strong>
          Number of schools by {title} in {year}
        </strong>
      </p>

      <div className={s.options}>
        {counts.map(({ name, percentage, count, color }, i) => (
          <div className={s.option} key={name}>
            <div className={s.name}>
              {text[name] || name}{" "}
              <span className={s.percentage}>({roundTo(percentage, 1)}%)</span>
            </div>
            <div className={s.chart}>
              <BarChart
                color={color}
                min={0}
                max={roundedMaxNumberOfSchools}
                value={count}
              />
            </div>
          </div>
        ))}
      </div>
      {description && <p className={s.notes}>{description}</p>}
    </div>
  );
};
export default IndicatorOverview;
