import React, { useState } from "react";
import s from "./style.module.css";

const TypeTester = ({
  typeSizes = [],
  letterSpacings = [],
  colors = [],
  weights = [],
  text = "The quick brown fox jumps over the lazy dog"
}) => {
  const [size, setSize] = useState(typeSizes[0]);
  const [weight, setWeight] = useState("regular");
  const [letterSpacing, setLetterSpacing] = useState(0);
  const [color, setColor] = useState("#000");
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const [alignment, setAlignment] = useState("center");

  const getOptions = token =>
    Object.entries(token.values).map(([key, value]) => (
      <option>
        {token.prefix}-{key}
      </option>
    ));

  return (
    <div>
      <form className={s.options}>
        <p>
          <label>Size</label>
          <select onChange={e => setSize(e.target.value)}>
            {getOptions(typeSizes)}
          </select>
        </p>

        <p>
          <label>Weight</label>
          <select onChange={e => setWeight(e.target.value)}>
            {getOptions(weights)}
          </select>
        </p>

        <p>
          <label>Letter spacing</label>
          <select onChange={e => setLetterSpacing(e.target.value)}>
            {getOptions(letterSpacings)}
          </select>
        </p>

        <p>
          <label>Color</label>
          <select onChange={e => setColor(e.target.value)}>
            {getOptions(colors)}
          </select>
        </p>

        <p>
          <label>Background</label>
          <select
            onChange={e => setBackgroundColor(e.target.value)}
            value={backgroundColor}
          >
            {getOptions(colors)}
          </select>
        </p>

        <p>
          <label>Alignment</label>
          <select
            onChange={e => setAlignment(e.target.value)}
            value={alignment}
          >
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </p>
      </form>

      <p
        className={s.text}
        contentEditable
        key={`${size}${weight}${letterSpacing}${color}${backgroundColor}`}
        style={{
          fontSize: `var(--${size})`,
          "font-weight": `var(--${weight})`,
          letterSpacing: `var(--${letterSpacing})`,
          color: `var(--${color})`,
          backgroundColor: `var(--${backgroundColor})`,
          textAlign: alignment
        }}
      >
        {text}
      </p>
      {/* <p>
        <div>
          Contrast: {contrast.ratio(color, backgroundColor)}
          <br />
          Score: {contrast.score(color, backgroundColor)}
          <br />
          Is accessible:{" "}
          {contrast.isAccessible(color, backgroundColor) ? "Pass" : "Fail"}
        </div>
      </p> */}
    </div>
  );
};

export default TypeTester;
