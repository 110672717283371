import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Analytics from "react-router-ga";

import Home from "./pages/Home";
import SchoolsIndex from "./pages/SchoolsList";
import SchoolShow from "./pages/School";
import Explorer from "./pages/Explorer";
import MetricComparison from "./pages/MetricComparison";
import LearningOutcomes from "./pages/LearningOutcomes";
import Styleguide from "./pages/Styleguide";
import Map from "./pages/Map";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Analytics id={process.env.REACT_APP_GA_ID}>
          <Switch>
            <Route path="/styleguide" component={Styleguide} />
            <Route path="/overview" component={LearningOutcomes} />
            <Route path="/schools/:id" component={SchoolShow} />
            <Route path="/schools" component={SchoolsIndex} />
            <Route path="/explorer" component={Explorer} />
            <Route path="/compare" component={MetricComparison} />
            <Route path="/map" component={Map} />
            <Route path="/" component={Home} />
          </Switch>
        </Analytics>
      </Router>
    </div>
  );
};

export default App;
