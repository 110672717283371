import React from "react";
import s from "./style.module.css";
import Icon from "../Icon";
import { generic as strings } from "../../strings.js";
import cn from "classnames";

const DataCard = ({ data, fieldToDisplay }) => {
  const value = data[fieldToDisplay.colName];

  const perStudentField = `students_per_${fieldToDisplay.colName}`;
  const hasPerStudentField = data[perStudentField] !== undefined;

  const percetageOfField = `percentage_of_parent_${fieldToDisplay.colName}`;
  const hasPercentageOfField = data[percetageOfField] !== undefined;

  const isNull = value === null;

  const unitValue = value === 1 ? "unit_singular" : "unit_plural";

  const formatIfTelephone = (colName, value) => {
    if (colName === "sch_mobile") {
      return `+232 ${value.replace(/(.{2})/g, "$1 ")}`;
    }

    return value;
  };

  return (
    <div className={cn(s.column, { [s.isNull]: isNull })}>
      <span className={s.name}>
        {fieldToDisplay.prettyName}

        {fieldToDisplay.description && (
          <span>
            {" "}
            <Icon
              type="question"
              color="null"
              tooltip={fieldToDisplay.description}
            />
          </span>
        )}
      </span>
      <span className={s.value}>
        {isNull
          ? strings.noData
          : formatIfTelephone(fieldToDisplay.colName, value)}

        {fieldToDisplay[unitValue] && !isNull && (
          <span> {fieldToDisplay[unitValue]}</span>
        )}

        {hasPerStudentField && (
          <span className={s.additionalValue}>
            ({data[perStudentField]} students per {fieldToDisplay.unit_singular}
            )
          </span>
        )}

        {hasPercentageOfField && (
          <span className={s.additionalValue}>
            ({data[percetageOfField]}% of total {fieldToDisplay.unit_plural})
          </span>
        )}
      </span>
    </div>
  );
};

export default DataCard;
