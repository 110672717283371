import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import { schoolsIndex } from "../../strings";
import { getFilteredSchools, getSchools } from "../../helpers/api";
import DataTable from "../../components/DataTable/index.js";
import SearchBar from "../../components/SearchBar";
import Button from "../../components/Button";
import FilterSchools from "../../components/FilterSchools";
import Box from "../../components/Box";
import { filterData } from "../../helpers/dataTasks";
import s from "./style.module.css";
import qs from "query-string";
import useAPI from "../../helpers/useAPI";
import { useDeepCompareMemo } from "use-deep-compare";

const SchoolsList = ({ history, location }) => {
  const search = qs.parse(location.search) || {};
  const pageHasSearchQuery = Object.keys(search).length !== 0;
  const [filters, setFilters] = useState(search);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterIsVisible, setFilterVisiblitity] = useState(pageHasSearchQuery);

  const filterMemo = useDeepCompareMemo(() => {
    return filters;
  }, [filters]);

  const hasFiltersOn = Object.keys(filters).length;
  const fetchFunc = hasFiltersOn ? getFilteredSchools : getSchools;

  const { data, meta, isLoading, hasError } = useAPI(fetchFunc, filterMemo);

  useEffect(() => {
    history.replace({
      search: qs.stringify(filters),
    });
  }, [filters, history]);

  const toggleFilterVisibility = () => {
    setFilters({});
    setFilterVisiblitity(!filterIsVisible);
  };

  const filteredData = filterData(data, "idschool_name", searchTerm);

  return (
    <Page
      title={schoolsIndex.title.replace(
        "$numberOfSchools",
        filteredData.length.toLocaleString()
      )}
      isLoading={isLoading}
      hasError={hasError}
      actionsArea={
        <div className={s.tableOptions}>
          <SearchBar
            handleSearch={setSearchTerm}
            placeholderText={schoolsIndex.actions.searchText}
          />
          <Button
            onClick={toggleFilterVisibility}
            forceActive={hasFiltersOn || filterIsVisible}
            iconBefore={"filter"}
          >
            {schoolsIndex.actions.filterText}
          </Button>
        </div>
      }
    >
      {filterIsVisible && (
        <FilterSchools
          meta={meta}
          filters={filters}
          onFilter={setFilters}
          text={schoolsIndex.actions}
          filterableOptions={meta.filter((d) =>
            [
              "idregion",
              "iddistrict",
              "sch_type",
              "sch_owner",
              "approval_status",
              "fin_support",
              "shift_status",
              "mixed_school",
              "boarding",
              "library",
              "sch_feeding",
              "sci_lab",
              "elec_grid",
              "generator",
              "computers",
              "drink_water",
              "source_protected",
              "avail_latrine_fac",
              "latrine_condition",
              "rec_facilities",
              "func_rec_facilities",
            ].includes(d.colName)
          )}
        />
      )}

      <Box>
        <DataTable data={filteredData} passRateBoundary={50} />
      </Box>
    </Page>
  );
};
export default SchoolsList;
