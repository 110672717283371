import React from "react";
import { ReactComponent as ArrowLeft } from "../../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { ReactComponent as ArrowSwitch } from "../../icons/arrow-switch.svg";
import { ReactComponent as Cross } from "../../icons/cross.svg";
import { ReactComponent as Download } from "../../icons/download.svg";
import { ReactComponent as Exclamation } from "../../icons/exclamation.svg";
import { ReactComponent as Expand } from "../../icons/expand.svg";
import { ReactComponent as Filter } from "../../icons/filters.svg";
import { ReactComponent as Minus } from "../../icons/minus.svg";
import { ReactComponent as Tick } from "../../icons/tick.svg";
import { ReactComponent as Plus } from "../../icons/plus.svg";
import { ReactComponent as Search } from "../../icons/search.svg";
import { ReactComponent as Question } from "../../icons/question.svg";
import { ReactComponent as NoData } from "../../icons/no-data.svg";
import Tooltip from "../Tooltip";

import cn from "classnames";
import s from "./style.module.css";

const Icon = ({
  type,
  active = false,
  direction,
  color,
  size = "md",
  tooltip
}) => (
  <span
    className={cn(s.icon, s.active, s[direction], s[size], {
      [s[color]]: color,
      [s.withBackground]: color,
      [s.withTooltip]: tooltip
    })}
  >
    {tooltip && <Tooltip top>{tooltip}</Tooltip>}
    {type === "arrow" && <ArrowRight />}
    {type === "arrowLeft" && <ArrowLeft />}
    {type === "arrowRight" && <ArrowRight />}
    {type === "arrowSwitch" && <ArrowSwitch />}
    {type === "cross" && <Cross />}
    {type === "download" && <Download />}
    {type === "exclamation" && <Exclamation />}
    {type === "expand" && <Expand />}
    {type === "filter" && <Filter />}
    {type === "minus" && <Minus />}
    {type === "noData" && <NoData />}
    {type === "plus" && <Plus />}
    {type === "question" && <Question />}
    {type === "search" && <Search />}
    {type === "tick" && <Tick />}
  </span>
);

export default Icon;
