import React, { Children } from "react";
import s from "./style.module.css";

const BoxInner = ({ children, columns = 2, ...rest }) => {
  return (
    <div
      {...rest}
      className={s.boxInner}
      style={{
        "--columns": columns,
      }}
    >
      <div className={s.wrap}>
        {Children.map(children.filter(Boolean), (child) => (
          <div
            className={s.gridItem}
            style={{
              "--span": child.props.gridSpan,
            }}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoxInner;
