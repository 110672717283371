import React, { useState } from "react";
import Box from "../../components/Box";
import BoxInner from "../../components/BoxInner";
import LineChart from "../../components/LineChart";
import Note from "../../components/Note";
import s from "./style.module.css";
import { exams } from "../../config";

const PassRateByIndicator = ({
  data,
  overallPassRate,
  name,
  columns = 2,
  note,
  notes = {},
}) => {
  const [level, setLevel] = useState("overall");

  const getData = (level, breakdown) => {
    const levelData = breakdown[level];
    if (!levelData) return [];
    return levelData;
  };

  return (
    <Box
      hasTooltips
      title={`Student Pass Rate by ${name}`}
      additionalHeaderInfo={
        <div className={s.levelWrap}>
          <select value={level} onChange={(e) => setLevel(e.target.value)}>
            <option value="overall">All education levels</option>
            <option value="primary">{exams.primary.name}</option>
            <option value="junior">{exams.junior.name}</option>
            <option value="senior">{exams.senior.name}</option>
          </select>
        </div>
      }
    >
      <BoxInner columns={columns}>
        {data.map(({ label, breakdown, color }) => (
          <LineChart
            key={label}
            min={0}
            max={100}
            suffix={"%"}
            title={label}
            cleanData={getData(level, breakdown)}
            hideYAxis
            color={color}
            note={notes[label]}
          />
        ))}

        {note && <Note gridSpan={columns}>{note}</Note>}
      </BoxInner>
    </Box>
  );
};
export default PassRateByIndicator;
