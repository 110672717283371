import React, { useEffect } from "react";
import s from "./styles.module.css";
import Container from "../Container";
import NavBar from "../NavBar";
import Loader from "../Loader";
import ImprovementBanner from "../ImprovementBanner";
import Box from "../Box";
import cn from "classnames";
import { footer as strings } from "../../strings";

const Page = ({
  children,
  title,
  fullScreenContent,
  isLoading,
  hasError,
  loggedOut,
  actionsArea,
  showcase,
  ...rest
}) => {
  useEffect(() => {
    document.title = `DSTI Education Data Explorer – ${title}`;
  }, [title]);

  return (
    <div
      className={cn(s.page, {
        [s.fullScreenContent]: fullScreenContent,
        [s.showcase]: showcase,
      })}
    >
      <div className={s.navBar}>
        <NavBar loggedOut={loggedOut} />
      </div>

      {hasError && (
        <div className={s.error}>
          <Box tight>Something went wrong. Please try again.</Box>
        </div>
      )}

      <main className={s.contentWrap}>
        {process.env.REACT_APP_SHOW_IMPROVEMENT_BANNER === "true" && (
          <div className={s.banner}>
            <ImprovementBanner />
          </div>
        )}

        <Container {...rest} fullScreenContent={fullScreenContent}>
          {title && !fullScreenContent && (
            <div className={s.header}>
              <h1 className={s.title}>{title}</h1>

              {actionsArea && <div>{actionsArea}</div>}
            </div>
          )}

          <div className={s.content}>{children}</div>
        </Container>

        <Loader isVisible={isLoading} />
      </main>

      {!fullScreenContent && (
        <footer className={s.footer}>
          <Container>
            <p className={s.logo}>
              <img
                src="/logo-large.svg"
                alt="Directorate of Science, Technology and Innovation"
              />
            </p>

            <p>{strings.text}</p>
          </Container>
        </footer>
      )}
    </div>
  );
};

export default Page;
