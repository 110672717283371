import React from "react";
import s from "./style.module.css";
import { getExamDetailsByKey } from "../../config";

const SourcesAndNotes = ({
  type = "primary",
  yearOfData = process.env.REACT_APP_DATA_YEAR,
  numberOfMissingSchools = 3000,
}) => {
  const { about, name, examName } = getExamDetailsByKey(type);

  return (
    <div className={s.wrap}>
      <div>
        <h3>About the {examName}</h3>
        <p>{about}</p>
      </div>

      <div>
        <h3>Methodology</h3>

        <p>
          Data shown includes all {name} schools with valid exam codes from the{" "}
          {yearOfData} EMIS dataset. Please note there are{" "}
          {numberOfMissingSchools} {name} schools without valid exam codes that
          have been excluded.
        </p>

        <p>
          <strong>
            Average {examName} scores have been calculated as follows:
          </strong>
        </p>
        <ol>
          <li>Calculating the mean score in each subject for each school.</li>
          <li>
            Adding up the mean scores in each subject for each school to get a
            combined total {examName} score for each school.
          </li>
          <li>
            Calculating the mean of a particular group of schools’ combined
            total {examName} scores to form an “Average {examName} score” for
            that particular group of schools (for example the average {examName}{" "}
            score for all {name} schools that have a garden).
          </li>
        </ol>

        <p>
          <strong>
            Divergence from average {examName} score has been calculated as
            follows:
          </strong>
        </p>
        <ol>
          <li>
            Taking the average {examName} score for a particular group of
            schools (for example the average {examName} score for all {name}{" "}
            schools that have a garden).
          </li>
          <li>
            Calculating the average of all the average {examName} scores (mean
            of means) for schools that gave a particular response to an
            indicator.
          </li>
          <li>Calculating the difference between the two.</li>
        </ol>
      </div>
    </div>
  );
};

export default SourcesAndNotes;
