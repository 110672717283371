import React from "react";
import s from "./styles.module.css";
import cn from "classnames";
import DistributionChart from "../MetricCharts/DistributionChart";
import PercentageChange from "../MetricCharts/PercentageChange";
import BarChart from "../MetricCharts/BarChart";
import Axis from "../MetricCharts/Axis";
import Box from "../Box";
import SourcesAndNotes from "../SourcesAndNotes";
import getRoundedUpValue from "../../helpers/getRoundedUpValue";
import getMinMaxForChange from "../../helpers/getMinMaxForChange";
import roundTo from "../../helpers/roundTo";
import { compare, chartTypes } from "../../strings";
import { getExamDetailsByKey } from "../../config";
import stringReplace from "../../helpers/stringReplace";

const MetricCompare = ({
  chartType,
  title,
  sentenceBuilder,
  countOfSchoolsWithMissingData,
  countOfMissingSchools,
  noDataForQuestion,
  numberOfSchoolsMax,
  options,
  maxCountPerResult,
  sampleMean,
  level,
  secondMetric,
}) => {
  const exam = getExamDetailsByKey(level);
  const roundedNumberOfSchoolsMax = getRoundedUpValue(numberOfSchoolsMax);
  const joinedOptions = [].concat(...options.map((d) => d.options));
  const { min, max, size } = getMinMaxForChange(joinedOptions);
  const changeMin = sampleMean * (exam.min > exam.max ? max : min);
  const changeMax = sampleMean * (exam.min > exam.max ? min : max);

  const upperCaseFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <Box
      header={
        <div className={s.vizHeader}>
          <h2
            dangerouslySetInnerHTML={{
              __html: stringReplace(compare.chartTitle, {
                $oneSentenceBuilder: upperCaseFirstLetter(sentenceBuilder),
                $twosentenceBuilder: secondMetric.sentenceBuilder,
                $examName: exam.examName,
                $exam: exam.name,
              }),
            }}
          />
        </div>
      }
    >
      <p>
        {stringReplace(chartTypes[chartType], {
          $examName: exam.examName,
        })}
      </p>

      {!noDataForQuestion && (
        <div className={s.topLevel}>
          {options.map((option, i) => (
            <div key={i}>
              {i === 0 && (
                <div className={cn(s.grid, s.gridSpaced)}>
                  <div className={s.column} />
                  {option.options.map(({ singleName }) => (
                    <div key={singleName} className={s.column}>
                      <p className={s.nameTwo}>{singleName}</p>
                    </div>
                  ))}
                </div>
              )}

              <div className={s.grid}>
                <div
                  className={cn(s.column, {
                    [s.columnWithUnderline]: i !== options.length - 1,
                  })}
                >
                  <p className={cn(s.rowTitle, s.nameOne)}>
                    {option.singleName}
                  </p>
                </div>
                {option.options.map(
                  (
                    {
                      name,
                      changeFromSampleMean,
                      countPerResult,
                      singleName,
                      hoverName,
                      isNumerical,
                      count,
                      ...box
                    },
                    j
                  ) => (
                    <div
                      key={name}
                      className={cn(s.column, {
                        [s.columnWithUnderline]: i !== options.length - 1,
                      })}
                    >
                      <div className={s.choices}>
                        {chartType === "numberOfSchools" && (
                          <div>
                            <BarChart
                              value={count}
                              min={0}
                              max={roundedNumberOfSchoolsMax}
                            />
                          </div>
                        )}

                        {chartType === "distribution" && (
                          <div>
                            <div className={s.heatmap}>
                              <DistributionChart
                                data={countPerResult}
                                min={exam.min}
                                max={exam.max}
                                maxSchools={maxCountPerResult}
                                changeLineMin={changeMin}
                                changeLineMax={changeMax}
                                sampleMean={sampleMean}
                                indicator={option.hoverName}
                                indicatorTwo={hoverName}
                                indicatorOneIsNumeric={option.isNumerical}
                                indicatorTwoIsNumeric={isNumerical}
                              />
                            </div>
                          </div>
                        )}

                        {chartType === "percentageChange" && (
                          <div>
                            <PercentageChange
                              score={box.mean}
                              change={changeFromSampleMean}
                              min={size}
                              max={-size}
                              isBackwards={exam.min > exam.max}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
              {i === options.length - 1 && (
                <div className={s.grid}>
                  <div className={s.column} />
                  {option.options.map(({ singleName }) => (
                    <div className={s.column} key={singleName}>
                      {chartType === "numberOfSchools" && (
                        <div>
                          <Axis min={0} max={roundedNumberOfSchoolsMax} />
                        </div>
                      )}

                      {chartType === "distribution" && (
                        <div>
                          <Axis
                            min={exam.min}
                            max={exam.max}
                            ticks={[
                              { value: exam.passMark, text: "Pass mark" },
                            ]}
                          />
                        </div>
                      )}

                      {chartType === "percentageChange" && (
                        <div>
                          <Axis
                            min={changeMin}
                            max={changeMax}
                            includeMid
                            suffix={""}
                            mid={roundTo(sampleMean, 1)}
                            midText={"Average score"}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {noDataForQuestion && <p>No data available</p>}

      {!noDataForQuestion && (
        <p className={s.missingData}>
          {countOfSchoolsWithMissingData > 0 && (
            <span>
              {compare.footer.replace(
                "$schools",
                countOfSchoolsWithMissingData
              )}
            </span>
          )}
        </p>
      )}

      <SourcesAndNotes
        type={level}
        numberOfMissingSchools={countOfMissingSchools}
      />
    </Box>
  );
};

export default MetricCompare;
