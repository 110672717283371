import React from "react";
import s from "./style.module.css";
import Label from "../Label";
import Button from "../../../components/Button";

const Buttons = ({ children, ...rest }) => {
  return (
    <div className={s.wrap}>
      <div className={s.button}>
        <Button {...rest}>{children}</Button>
        <Label>Button</Label>
      </div>
      <div className={s.button}>
        <Button forceActive {...rest}>
          {children}
        </Button>
        <Label>Active</Label>
      </div>
      <div className={s.button}>
        <Button disabled {...rest}>
          {children}
        </Button>
        <Label>Disabled</Label>
      </div>
    </div>
  );
};

export default Buttons;
