import { generic as strings } from "../strings.js";

export const getMetaByCategories = (
  meta,
  filter,
  level = "primary",
  useMetaCategory
) => {
  const filtered = filter ? meta.filter(row => row[filter] === true) : meta;

  const fieldsToHidePerLevel = {
    preprimary: [
      "pass_rate",
      "sat",
      "avg_score_p",
      "avg_score_js",
      "avg_score_ss"
    ],
    primary: ["avg_score_js", "avg_score_ss"],
    junior: ["avg_score_p", "avg_score_ss"],
    senior: ["avg_score_p", "avg_score_js"]
  };

  const filtedByLevel = filtered.filter(
    row => !fieldsToHidePerLevel[level].includes(row.colName)
  );

  const key = useMetaCategory ? "metacategory" : "category";

  return [...new Set(filtered.map(d => d[key]))]
    .map(category => ({
      title: category,
      data: filtedByLevel.filter(row => row[key] === category)
    }))
    .filter(({ title, data }) => title && data.length);
};

export const formatOption = (option, isLast, isFirst) => {
  if (option.includes("[") && option.includes(")")) {
    const options = option
      .replace(/[[)]/g, "")
      .split(",")
      .map(d => +d.trim());

    let [min, max] = options;

    if (min % Math.round(min) === 0) {
      min = Math.round(min);
    }

    if (min === 0.0) {
      min = 0;
    }

    if (max % Math.round(max) === 0) {
      max = Math.round(max);
    }

    if (max - min === 1) {
      return min;
    }

    if (isLast) {
      return `${min}+`;
    }

    if (isFirst) {
      return `${min}`;
    }

    return `${min} – ${max}`;
  }

  if (option === "nan") {
    return strings.noData;
  }

  return option;
};
