export const filterData = (data, searchField, searchTerm) => {
  if (!data) {
    return [];
  }

  return data.filter((record) => {
    const col = record[searchField] || "";

    return col.toUpperCase().includes(searchTerm.toUpperCase());
  });
};

export const sortData = (data, sortBy, sortASC) => {
  return data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortASC ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sortASC ? 1 : -1;
    }
    return 0;
  });
};
