// -------------------- SCHOOL INDEX --------------------
//Columns which appear in the schools index table.
export const schoolIndexFields = [
  "id",
  "idschool_name",
  "sch_type",
  "iddistrict",
  "idregion",
  "pass_rate",
];

// -------------------- SCHOOL PROFILE --------------------
// School profile basic info fields.
// These appear at the top of the school profile page - each array is a row.
export const basicInformationFields = [
  "emiscode",
  "sch_waec",
  "sch_type",
  "sch_owner",
  "teacher_count",
  "total_enrollment",
  "mixed_school",
  "idregion",
  "iddistrict",
  "idtown",
  "idchiefdom",
];

// -------------------- EXAM DETAILS --------------------
// Information about each exam
export const exams = {
  preprimary: {
    dataName: "Pre-Primary",
    name: "Pre-Primary",
    exam: false,
  },
  primary: {
    dataName: "Primary",
    name: "Primary",
    exam: "Average NPSE score",
    examName: "NPSE",
    min: 0,
    max: 500,
    passMark: 230,
    extraTicks: [250],
    resultsKey: "avg_score_p",
    dbKey: "results_p",
    minGrade: 0,
    maxGrade: 100,
    subjects: [
      { key: "Average", name: "All subjects (average)" },
      { key: "Quantitative Analysis", name: "Quantitative Analysis" },
      { key: "Verbal Aptitude", name: "Verbal Aptitude" },
      { key: "Mathematics", name: "Mathematics" },
      { key: "English Language", name: "English Language" },
      { key: "General Science", name: "General Science" },
    ],
    passRateExplanation:
      "To pass the NPSE, students must achieve a total score of at least 230.",
    about:
      "The National Primary School Examination (NPSE) is taken in the final grade of primary school. Students who pass this exam graduate onto junior secondary school. Students take exams in five subjects: Quantitative Analysis, Verbal Aptitude, Mathematics, English Language and General Science. In each subject students can get a maximum score of 100 and a combined total score of 500 across all subjects. All five subjects are compulsory for students to take. The Ministry of Basic and Senior Secondary Education (MBSSE) has set the pass mark for the NPSE at 230.",
    shortAbout:
      "Students take exams in five subjects. In each subject students can get a maximum score of 100 and a combined total score of 500 across all subjects.",
  },
  junior: {
    dataName: "Junior Secondary",
    name: "Junior Secondary",
    exam: "Average BECE score",
    examName: "BECE",
    min: 7,
    max: 1,
    passMark: 6,
    extraTicks: [],
    resultsKey: "avg_score_js",
    dbKey: "results_js",
    minGrade: 7,
    maxGrade: 1,
    subjects: [
      { key: "Average", name: "All subjects (average)" },
      { key: "Integrated Sciences", name: "Integrated Sciences" },
      { key: "English Language", name: "English Language" },
      { key: "Mathematics", name: "Mathematics" },
      { key: "Social Studies", name: "Social Studies" },
    ],
    passRateExplanation:
      "To pass the BECE, students must achieve a minimum grade of 6 in at least six subjects (four core and two optional)",
    about:
      "The Basic Education Certificate Examination (BECE) is taken in the final grade in junior secondary school. Students who pass this exam graduate onto senior secondary school. Students take exams in eight subjects. These are split into four ‘core’ subjects (Mathematics, English Language, Social Studies and Integrated Science) and four ‘optional’ subjects. In each subject students can get a score ranging from 1 (Excellent) to 7 (Fail). The pass mark for each subject is 6. Students must pass the four core subjects and two optional subjects in order to graduate onto senior secondary school. The overall pass mark for the BECE is 36 (a score of ‘6’ across six subjects).",
    shortAbout:
      "Students take exams in eight subjects. In each subject students can get a score ranging from 1 (Excellent) to 7 (Fail).",
  },
  senior: {
    dataName: "Senior Secondary",
    name: "Senior Secondary",
    exam: "Average WASSCE score",
    examName: "WASSCE",
    min: 9,
    max: 1,
    minGrade: 9,
    maxGrade: 1,
    passMark: 8,
    extraTicks: [3, 5, 7],
    resultsKey: "avg_score_ss",
    dbKey: "results_ss",
    subjects: [
      { key: "Average", name: "All subjects (average)" },
      { key: "English Language", name: "English Language" },
      { key: "Mathematics", name: "Mathematics" },
    ],
    passRateExplanation:
      "To pass the WASSCE, students must achieve a minimum grade of 6 (Credit) in at least five subjects.",
    about:
      "The West African Senior School Certificate Examination (WASSCE) is taken in the third year of senior secondary school. Students take exams in nine subjects. These are split into two compulsory subjects (Mathematics and English Language) and seven subjects of the student’s choice. In each subject students can get a numeric grade ranging from 1 (Excellent) to 9 (Fail). The pass mark for each subject is 8. Students must pass at least five subjects (including Mathematics or English Language) with a numeric grade of 6 (Credit) or higher in order to qualify for university study.",
    shortAbout:
      "Students take exams in nine subjects. In each subject students can get a numeric grade ranging from 1 (Excellent) to 9 (Fail).",
  },
};

export const getExamDetailsByKey = (key) => exams[key];

export const getExamDetailsByName = (name) => {
  const [key] = Object.entries(exams).find(([key, details]) => {
    return details.name.toLowerCase() === name.toLowerCase();
  });

  return getExamDetailsByKey(key);
};

export const getExamDetailsAsArray = () =>
  Object.entries(exams).map(([key, details]) => ({
    ...details,
    key,
  }));
