import React from "react";
import Box from "../../components/Box";
import ImageGallery from "../../components/ImageGallery";
import s from "./style.module.css";
import DataCard from "../DataCard";
import { basicInformationFields } from "../../config.js";
import { school } from "../../strings.js";

const getImages = (id, images) => {
  if (!images) return [];

  return images
    .split(",")
    .map((d) => {
      let [id, isAvailable] = d.split("|");

      return {
        id,
        isAvailable,
        url: `${process.env.REACT_APP_SCHOOL_IMAGES_URL}/${id}`,
      };
    })
    .filter((d) => d.isAvailable === "TRUE");
};

const DataField = ({ field, data, meta }) => (
  <div className={s.field}>
    <DataCard data={data} fieldToDisplay={meta[field]} />
  </div>
);

const SchoolProfileHeader = ({ data, meta }) => {
  const showImages = process.env.REACT_APP_ENABLE_SCHOOL_IMAGES === "true";
  return (
    <header className={s.header}>
      <Box title={school.boxTitles.keyInfo}>
        <div className={s.container}>
          {!showImages && (
            <>
              <div className={s.details}>
                {basicInformationFields.slice(0, 7).map((field) => (
                  <DataField
                    field={field}
                    meta={meta}
                    data={data}
                    key={field}
                  />
                ))}
              </div>

              <div className={s.details}>
                {basicInformationFields.slice(7, 12).map((field) => (
                  <DataField
                    field={field}
                    meta={meta}
                    data={data}
                    key={field}
                  />
                ))}
              </div>
            </>
          )}

          {showImages && (
            <>
              <div className={s.details}>
                {basicInformationFields.map((field) => (
                  <DataField
                    field={field}
                    meta={meta}
                    data={data}
                    key={field}
                  />
                ))}
              </div>

              <div className={s.extra}>
                <ImageGallery images={getImages(data.id, data.images)} />
              </div>
            </>
          )}
        </div>
      </Box>
    </header>
  );
};

export default SchoolProfileHeader;
