import React from "react";
import s from "./styles.module.css";
import Tooltip from "../../Tooltip";
import { area, curveStepAfter } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { colors } from "../../../system";

const DistributionChart = ({
  data = [],
  min = 0,
  max = 1,
  maxSchools,
  indicator,
  indicatorTwo,
  changeLineMin,
  changeLineMax,
  sampleMean,
  color = colors.values.midBlue,
  width = 448,
  height = 76,
  showSampleBox,
  indicatorOneIsNumeric,
  indicatorTwoIsNumeric,
  rounding,
  ...rest
}) => {
  const y = scaleLinear().domain([0, maxSchools]).range([height, 0]);

  const x = scaleLinear().domain([min, max]).range([0, 1]);

  const areaPlot = area()
    .x((d) => x(d.score[0]) * width)
    .y1((d) => (d.num === 0 ? height + 1 : Math.max(y(d.num), height / 100)))
    .y0((d) => (d.num === 0 ? height + 1 : height))
    .curve(curveStepAfter);

  const lowerCaseFirstLetter = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

  const getTooltip = (
    num,
    indicator,
    score,
    indicatorOneIsNumeric,
    indicatorTwo,
    indicatorTwoIsNumeric
  ) => {
    return (
      <div>
        <strong>{num}</strong> school{num === 1 ? "" : "s"}
        {indicatorOneIsNumeric ? " " : " that "}{" "}
        {lowerCaseFirstLetter(indicator)}{" "}
        {indicatorTwo && (
          <span>
            and
            {indicatorTwoIsNumeric ? " " : " that "} also{" "}
            {lowerCaseFirstLetter(indicatorTwo)}{" "}
          </span>
        )}
        {num === 1 ? "has" : "have"} a score between{" "}
        <strong key={"score"}>
          {score[0].toFixed(rounding)} and {score[1].toFixed(rounding)}
        </strong>
      </div>
    );
  };

  return (
    <div className={s.wrap}>
      <div className={s.chart}>
        <svg
          className={s.svg}
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio="none"
        >
          <path
            d={areaPlot(data)}
            fill={color}
            stroke={color}
            strokeWidth={1}
            fillOpacity={0.2}
          />
        </svg>

        {data.map(({ value, count, num, score }, i) => (
          <span
            key={i}
            className={s.bar}
            style={{
              "--left": x(min > max ? score[1] : score[0]),
              "--width": Math.abs(x(score[1]) - x(score[0])),
            }}
          >
            {num !== 0 && (
              <Tooltip top darkenArea>
                {getTooltip(
                  num,
                  indicator,
                  score,
                  indicatorOneIsNumeric,
                  indicatorTwo,
                  indicatorTwoIsNumeric
                )}
              </Tooltip>
            )}
          </span>
        ))}

        {showSampleBox && (
          <div>
            <span
              className={s.percentageBox}
              style={{
                "--position": x(changeLineMin),
                "--width": x(changeLineMax) - x(changeLineMin),
              }}
            />
            <span
              className={s.sampleMean}
              style={{ "--position": x(sampleMean) }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DistributionChart;
