import React from "react";
import system from "../../system";
import Container from "../../components/Container";
import LineChart from "../../components/LineChart";
import Section from "./Section";
import TypographyShowcase from "./TypographyShowcase";
import FontScale from "./FontScale";
import LetterSpacingScale from "./LetterSpacingScale";
import SpacingScale from "./SpacingScale";
import TypeTester from "./TypeTester";
import Inputs from "./Inputs";
import Icons from "./Icons";
import Label from "./Label";
import Colors from "./Colors";
import Spacer from "./Spacer";
import Buttons from "./Buttons";
import Containers from "./Containers";
import ComponentShowcase from "./ComponentShowcase";
import Navigation from "./Navigation";
import s from "./style.module.css";

const allIcons = [
  "arrowLeft",
  "arrowRight",
  "cross",
  "exclamation",
  "expand",
  "filter",
  "minus",
  "noData",
  "plus",
  "question",
  "search",
  "tick",
];

const Styleguide = () => (
  <div className={s.styleguide}>
    <div className={s.navigation}>
      <Navigation components={["Buttons", "Containers", "Time charts"]} />
    </div>

    <div className={s.content}>
      <Container>
        <Section title="About">Styleguide for Education Hub</Section>
        <Section title="Colors">
          <Colors colors={system.colors} />
        </Section>
        <Section title="Typography">
          <TypographyShowcase weights={system.weights} />
        </Section>
        <Section title={system.typeSizes.name}>
          <FontScale sizes={system.typeSizes} />
        </Section>
        <Section title={system.spacing.name}>
          <SpacingScale sizes={system.spacing} />
        </Section>
        <Section title={system.letterSpacings.name}>
          <LetterSpacingScale sizes={system.letterSpacings} />
        </Section>
        <Section title="Type tester">
          <TypeTester {...system} />
        </Section>
        <Section title="Inputs">
          <Inputs />
        </Section>
        <Section title="Icons">
          <Icons icons={allIcons} size="sm" />
          <Label>sm</Label>
          <Spacer size="m" />
          <Icons icons={allIcons} size="md" />
          <Label>md</Label>
          <Spacer size="m" />
          <Icons icons={allIcons} color="null" />
          <Label>null</Label>
          <Spacer size="m" />
          <Icons icons={allIcons} color="neutral" />
          <Label>neutral</Label>
          <Spacer size="m" />
          <Icons icons={allIcons} color="positive" />
          <Label>positive</Label>
          <Spacer size="m" />
          <Icons icons={allIcons} color="negative" />
          <Label>negative</Label>
          <Spacer size="m" />
          <Icons icons={allIcons} color="warning" />
          <Label>warning</Label>
          <Icons icons={allIcons} color="subtle" />
          <Label>subtle</Label>
        </Section>{" "}
        <h1>Components</h1>
        <Section title="Buttons">
          <Buttons>Button</Buttons>
          <Spacer size="m" />
          <Buttons iconBefore={"search"}>Button</Buttons>
          <Spacer size="m" />
          <Buttons iconAfter={"filter"}>Button</Buttons>
          <Spacer size="m" />
          <Buttons iconBefore={"plus"} />

          <Spacer size="m" />
          <Buttons secondary>Button</Buttons>
          <Spacer size="m" />
          <Buttons secondary iconBefore={"search"}>
            Button
          </Buttons>
          <Spacer size="m" />
          <Buttons secondary iconAfter={"filter"}>
            Button
          </Buttons>
          <Spacer size="m" />
          <Buttons secondary iconBefore={"plus"} />
        </Section>
        <Section title="Containers">
          <Containers />
        </Section>
        <Section title="Time charts">
          <ComponentShowcase
            component={LineChart}
            props={[
              {
                showcaseName: "Simple",
                data: "2016|10,2017|20",
                min: 0,
                max: 100,
              },
              {
                showcaseName: "WithTitle",
                data: "2016|10,2017|20",
                min: 0,
                max: 100,
                title: "Chart title",
              },
              {
                showcaseName: "With more data",
                data: "2016|10,2017|20,2018|50,2019|100",
                min: 0,
                max: 100,
              },
              {
                showcaseName: "Different scale",
                data: "2016|0,2017|20,2018|5.6,2019|36",
                min: 0,
                max: 40,
              },
              {
                showcaseName: "With extra ticks",
                data: "2016|10,2017|20,2018|50,2019|100",
                min: 0,
                max: 100,
                extraTicks: [25, 50, 75],
              },
              {
                showcaseName: "Missing year",
                data: "2016|10,2017|50,2019|100",
                min: 0,
                max: 100,
              },
              {
                showcaseName: "With suffix",
                data: "2016|10,2017|50,2019|100",
                min: 0,
                max: 100,
                suffix: "%",
              },
              {
                showcaseName: "Single data point",
                data: "2016|50.1",
                min: 0,
                max: 100,
                suffix: "%",
              },
              {
                showcaseName: "As bar",
                data: "2016|10,2017|50,2018|100,2019|50",
                min: 0,
                max: 100,
                suffix: "%",
                useBar: true,
              },
            ]}
          />
        </Section>
      </Container>
    </div>
  </div>
);

export default Styleguide;
