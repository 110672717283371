import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Loader = ({ isVisible, isInternal }) => (
  <div
    className={cn(s.loader, {
      [s.isVisible]: isVisible,
      [s.isInternal]: isInternal
    })}
  >
    <div className={s.dots}>
      <div className={s.dot} />
      <div className={s.dot} />
      <div className={s.dot} />
    </div>
  </div>
);

export default Loader;
