import React from "react";
import Page from "../../components/Page";
import SchoolProfileHeader from "../../components/SchoolProfileHeader";
import ApprovalBar from "../../components/ApprovalBar";
import CatergorySelector from "../../components/CatergorySelector";
import ResultsByTime from "../../components/ResultsByTime";
import { getSchool } from "../../helpers/api";
import useAPI from "../../helpers/useAPI";
import { school } from "../../strings";
import DownloadButton from "../../components/DownloadButton";

const School = props => {
  const id = parseInt(props.match.params.id);
  const { data, meta, isLoading, hasError } = useAPI(getSchool, id);

  return (
    <Page
      title={data.idschool_name}
      isLoading={isLoading}
      hasError={hasError}
      actionsArea={
        <DownloadButton
          url={`/downloads/2018/school/${data.id}`}
          text={school.downloadButton}
        />
      }
    >
      {data && (
        <section>
          <SchoolProfileHeader
            data={data}
            meta={meta.reduce(
              (all, next) => ({ ...all, [next.colName]: next }),
              {}
            )}
          />

          <ApprovalBar
            meta={meta.reduce(
              (all, next) => ({ ...all, [next.colName]: next }),
              {}
            )}
            data={data}
          />

          <ResultsByTime data={data} />
          <CatergorySelector data={data} meta={meta} />
        </section>
      )}
    </Page>
  );
};

export default School;
