import React from "react";
import s from "./styles.module.css";
import { NavLink } from "react-router-dom";
import { header as strings } from "../../strings";
import Container from "../Container";

const NavBar = ({ loggedOut }) => {
  const links = [
    { to: "/overview", text: strings.navOptions.overview },
    { to: "/schools", text: strings.navOptions.schools },
    { to: "/explorer", text: strings.navOptions.explore },
    { to: "/compare", text: strings.navOptions.compare },
    { to: "/map", text: strings.navOptions.map }
  ];

  return (
    <div className={s.header}>
      <div className={s.headerBar}>
        <Container>
          <h1 className={s.home}>
            <NavLink className={s.homeLink} to="/">
              <img className={s.logo} alt="" src="/logo-simple.svg" />
              {strings.name}
            </NavLink>
          </h1>
        </Container>
      </div>

      {!loggedOut && (
        <div className={s.linkBar}>
          <Container>
            <div className={s.links}>
              {links.map(({ to, text }, index) => (
                <NavLink
                  key={index}
                  className={s.link}
                  activeClassName={s.active}
                  to={to}
                >
                  {text}
                </NavLink>
              ))}
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default NavBar;
