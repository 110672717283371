export default options => {
  const max = Math.max(...options.map(d => Math.abs(d.changeFromSampleMean)));

  const offset = Math.ceil(max / 5) * 5 + 5;

  return {
    min: 1 - offset / 100,
    max: 1 + offset / 100,
    size: offset
  };
};
