import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import Label from "../Label";

const TypographyShowcase = ({ weights }) => (
  <div className={s.outer}>
    {Object.entries(weights.values).map(([key, value]) => (
      <div>
        <p
          className={cn(s.wrap)}
          style={{
            fontWeight: `var(--${weights.prefix}-${value})`
          }}
        >
          <span className={s.showcase}>Aa</span>
          <span>
            <span>ABCDEFGHIJKLMNOPQRSTUVWXYZ</span>
            <br />
            <span>abcdefghijklmnopqrstuvwxyz</span>
            <br />
            <span>1234567890(,.;:?!$&*)</span>
          </span>
        </p>
        <p>
          <Label>{key}</Label>
        </p>
      </div>
    ))}
  </div>
);

export default TypographyShowcase;
