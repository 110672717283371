import React from "react";
import s from "./style.module.css";
import Box from "../Box";
import Approval from "../Approval";
import { school } from "../../strings";

const options = {
  Approved: {
    icon: "tick",
    highlight: "positive",
  },
  "Not approved": {
    icon: "cross",
    highlight: "negative",
  },
  "Applied for approval": {
    icon: "question",
    highlight: "neutral",
  },
};

const ApprovalBar = ({ meta, data }) => {
  const { highlight } = options[data.approval_status];

  return (
    <Box
      className={s.approvalBar}
      title={school.boxTitles.approval}
      additionalHeaderInfo={
        <div className={s.approvalHeader}>
          {data && <Approval status={data.approval_status} />}
        </div>
      }
      highlight={highlight}
      warning={
        data.not_approved_with_waec === "Yes" &&
        "This school is not approved but has a WAEC code"
      }
    ></Box>
  );
};

export default ApprovalBar;
