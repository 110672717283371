import React from "react";
import s from "./styles.module.css";
import cn from "classnames";

const Container = ({ children, className, fullScreenContent, ...rest }) => (
  <div
    {...rest}
    className={cn(s.container, {
      [className]: className,
      [s.fullScreenContent]: fullScreenContent
    })}
  >
    {children}
  </div>
);

export default Container;
