import React from "react";
import s from "./style.module.css";
import { titleToHash } from "../helpers";

const Navigation = ({
  main = [
    "Colors",
    "Typography",
    "Type sizes",
    "Letter spacing",
    "Type tester",
    "Spacing",
    "Inputs",
    "Icons"
  ],
  components = []
}) => (
  <div className={s.wrap}>
    <h1 className={s.heading}>
      <a className={s.link} href={`#about`}>
        Styleguide
      </a>
    </h1>

    <nav>
      <h2 className={s.title}>Generic</h2>
      {main.map(name => (
        <a className={s.link} href={`#${titleToHash(name)}`}>
          {name}
        </a>
      ))}

      <h2 className={s.title}>Components</h2>
      {components.map(name => (
        <a className={s.link} href={`#${titleToHash(name)}`}>
          {name}
        </a>
      ))}
    </nav>
  </div>
);

export default Navigation;
