export default [
  ["#FFBF00"],
  ["#FFBF00", "#FF7621"],
  ["#FFBF00", "#FF7621", "#FE0D4D"],
  ["#FFBF00", "#FF7621", "#FE0D4D", "#6E00BE"],
  ["#FFBF00", "#FF7621", "#FE0D4D", "#E20083", "#6E00BE"],
  ["#FFBF00", "#FF7621", "#FE0D4D", "#E20083", "#6E00BE", "#2500C9"],
  ["#FFBF00", "#FF9C04", "#FF7621", "#FE0D4D", "#E20083", "#6E00BE", "#2500C9"],
  [
    "#FFBF00",
    "#FF9C04",
    "#FF7621",
    "#FE0D4D",
    "#E20083",
    "#AE00A8",
    "#6E00BE",
    "#2500C9"
  ],
  [
    "#FFBF00",
    "#FF9C04",
    "#FF7621",
    "#FF4A37",
    "#FE0D4D",
    "#E20083",
    "#AE00A8",
    "#6E00BE",
    "#2500C9"
  ],
  [
    "#FFBF00",
    "#FF9C04",
    "#FF7621",
    "#FF4A37",
    "#FE0D4D",
    "#E20083",
    "#AE00A8",
    "#9300B2",
    "#6E00BE",
    "#2500C9"
  ],
  [
    "#FFBF00",
    "#FF9C04",
    "#FF7621",
    "#FF4A37",
    "#FE0D4D",
    "#E20083",
    "#C7009B",
    "#AE00A8",
    "#9300B2",
    "#6E00BE",
    "#2500C9"
  ],
  [
    "#FFBF00",
    "#FF9C04",
    "#FF7621",
    "#FF4A37",
    "#FE0D4D",
    "#F40069",
    "#E20083",
    "#C7009B",
    "#AE00A8",
    "#9300B2",
    "#6E00BE",
    "#2500C9"
  ]
];
