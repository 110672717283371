import React from "react";
import Label from "../Label";
import Spacer from "../Spacer";

const ComponentShowcase = ({ component: Component = "span", props = [] }) => (
  <div>
    {props.map(({ showcaseName, ...rest }) => (
      <div>
        <Component {...rest} />
        {showcaseName && <Label>{showcaseName}</Label>}
        <Spacer size="m" />
      </div>
    ))}
  </div>
);

export default ComponentShowcase;
