import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import WarningBox from "../WarningBox";

const Box = ({
  children,
  header,
  title,
  additionalHeaderInfo,
  className,
  tight,
  last,
  highlight,
  hasBoxShadow,
  hasTooltips,
  warning,
  ...rest
}) => (
  <div
    {...rest}
    className={cn(s.box, {
      [className]: className,
      [s.tight]: tight,
      [s.hasBoxShadow]: hasBoxShadow,
      [s.hasTooltips]: hasTooltips,
      [s.last]: last,
      [s.hasNoChildren]: !children,
    })}
  >
    {header && <div className={s.header}>{header}</div>}
    {title && (
      <div className={cn(s.header, s[highlight])}>
        <h3 className={s.title}>{title}</h3>
        {additionalHeaderInfo}
      </div>
    )}

    {warning && (
      <div className={s.warning}>
        <WarningBox full message={warning} />
      </div>
    )}

    {children}
  </div>
);

export default Box;
