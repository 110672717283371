import React, { useState, Suspense } from "react";
import Page from "../../components/Page";
import { getMappedResults } from "../../helpers/api";
import s from "./style.module.css";
import { map as strings } from "../../strings";
import MetricDropDown from "../../components/MetricDropDown";
import Container from "../../components/Container";
import Box from "../../components/Box";
import MapLegend from "../../components/MapLegend";
import swatches from "../../helpers/swatches";
import useAPI from "../../helpers/useAPI";
import { exams } from "../../config";
const MapPlot = React.lazy(() => import("../../components/MapPlot"));

const Map = (props) => {
  const [viewport] = useState([-11.5, 8.5]);
  const [bounds] = useState([
    [-13.3, 6.94],
    [-10.28, 9.99],
  ]);
  const [educationLevel, setEducationLevel] = useState("primary");
  const [metricTwo, setMetricTwo] = useState("approval_status");
  const [popup, changePopup] = useState({ display: false });

  const { data, meta, isLoading, hasError } = useAPI(
    getMappedResults,
    educationLevel,
    metricTwo
  );

  let options = [];
  let colors = [];
  const selected = meta.find(({ colName }) => colName === metricTwo);
  if (selected) {
    options = selected.options;
    colors =
      selected.dataType === "Numerical"
        ? swatches[options.length - 1]
        : selected.colours;
  }

  const cleanedOptions = options.map((option, i) => {
    if (typeof option === "object") {
      const match = `(${option[0]}, ${option[1]}]`;
      return [match, colors[i]];
    }
    return [option, colors[i]];
  });

  return (
    <Page
      fullScreenContent
      isLoading={isLoading}
      title={strings.title}
      hasError={hasError}
    >
      <div className={s.wrap}>
        <Container className={s.container}>
          <div className={s.options}>
            <Box tight hasBoxShadow>
              <p>
                <label>{strings.options.educationLevel}</label>
                <select
                  onChange={(e) => setEducationLevel(e.target.value)}
                  value={educationLevel}
                >
                  <option value="preprimary">{exams.preprimary.name}</option>
                  <option value="primary">{exams.primary.name}</option>
                  <option value="junior">{exams.junior.name}</option>
                  <option value="senior">{exams.senior.name}</option>
                </select>
              </p>

              <p>
                <label>{strings.options.chooseMetric}</label>
                <MetricDropDown
                  filter={"includeInMap"}
                  options={meta}
                  onChange={(e) => {
                    setMetricTwo(e.target.value);
                  }}
                  value={metricTwo}
                  level={educationLevel}
                />
              </p>
            </Box>
          </div>

          {selected && (
            <MapLegend
              className={s.legend}
              selected={selected}
              colors={colors}
              options={options}
            />
          )}
        </Container>

        <Suspense fallback={""}>
          {data && (
            <MapPlot
              viewport={viewport}
              bounds={bounds}
              cleanedOptions={cleanedOptions}
              results={data.filter((d) => d.value !== null)}
              value={metricTwo}
              selected={selected}
              popup={popup}
              changePopup={(data) => {
                changePopup(data);
              }}
            />
          )}
        </Suspense>
      </div>
    </Page>
  );
};

export default Map;
