import React from "react";
import { getMetaByCategories } from "../../helpers/meta";

const MetricDropDown = ({
  options,
  filter = "includeInDropdown",
  level,
  ...rest
}) => {
  const categories = getMetaByCategories(options, filter, level);

  return (
    <select {...rest}>
      {categories.map(({ title, data }, index) => (
        <optgroup key={index} label={title}>
          {data.map(({ prettyNameDropdown, colName }, index) => (
            <option key={index} value={colName}>
              {prettyNameDropdown}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );
};

export default MetricDropDown;
