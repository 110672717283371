import React from "react";
// import s from "./styles.module.css";
import Page from "../../components/Page";
import IndicatorOverview from "../../components/IndicatorOverview";
import GradeOverview from "../../components/GradeOverview";
import Note from "../../components/Note";
import Box from "../../components/Box";
import BoxInner from "../../components/BoxInner";
import LineChart from "../../components/LineChart";
import PassRateByIndicator from "../../components/PassRateByIndicator";
import { getOverview } from "../../helpers/api";
import useAPI from "../../helpers/useAPI";
import { exams } from "../../config";

import overview from "../../api/data/learning-outcomes/overview.json";
import byOwner from "../../api/data/learning-outcomes/by-owner.json";
import byDistrict from "../../api/data/learning-outcomes/by-district.json";
import byScore from "../../api/data/learning-outcomes/by-score.json";

const LearningOutcomes = (props) => {
  const { data, isLoading, hasError } = useAPI(getOverview);

  return (
    <Page
      isLoading={isLoading}
      hasError={hasError}
      title={"Overview of Key Performance Metrics"}
    >
      <div>
        {data && (
          <div>
            <Box title="School Approval and Exams Update">
              <BoxInner columns="2">
                <IndicatorOverview
                  title={"approval status"}
                  {...data.approval_status}
                  text={{
                    Approved: "Cumulative Approved (For all years)",
                    "Applied for approval": "Applied for Approval (2019)",
                    "Not approved": "Not Approved (2019)",
                  }}
                />
                <IndicatorOverview
                  title={"exam success status"}
                  {...data.exam_success}
                />
              </BoxInner>
            </Box>

            <Box title="Student Pass Rate">
              <BoxInner
                columns="3"
                note={
                  "Student Pass Rate is the percentage of students who passed in an examination for a particular program at the next level (NPSE for Primary, BECE for Junior Secondary, and WASSCE for Senior Secondary)."
                }
              >
                <LineChart
                  gridSpan={3}
                  min={0}
                  max={100}
                  suffix={"%"}
                  extraTicks={[25, 50, 75]}
                  title={"Percentage of students who passed (National average)"}
                  cleanData={overview.overall}
                  hideYAxis
                />

                <LineChart
                  min={0}
                  max={100}
                  suffix={"%"}
                  title={exams.primary.name}
                  cleanData={overview.primary}
                  hideYAxis
                />

                <LineChart
                  min={0}
                  max={100}
                  suffix={"%"}
                  title={exams.junior.name}
                  cleanData={overview.junior}
                  hideYAxis
                />
                <LineChart
                  min={0}
                  max={100}
                  suffix={"%"}
                  title={exams.senior.name}
                  cleanData={overview.senior}
                  hideYAxis
                />

                <Note gridSpan={3}>
                  Student Pass Rate is the percentage of students who passed in
                  an examination for a particular program at the next level
                  (NPSE for Primary, BECE for Junior Secondary, and WASSCE for
                  Senior Secondary).
                </Note>
              </BoxInner>
            </Box>

            <PassRateByIndicator
              name="District"
              data={byDistrict}
              columns={4}
              note={
                "Karene and Falaba Districts are two new districts formed in 2017 and data was not collected for them from 2016 – 2018. Thus, the missing data."
              }
            />

            <PassRateByIndicator
              name="Owner"
              data={byOwner}
              columns={3}
              notes={{
                Other:
                  "Public schools that are established by individuals, NGOs and other organizations that are neither government nor faith-based and the community cannot claim ownership.",
              }}
            />

            <Box title="Average Examination Grades by Subject">
              <BoxInner columns="3">
                <GradeOverview
                  level={"primary"}
                  data={byScore}
                  note={"NPSE Subject Score range from 0 – 100."}
                />
                <GradeOverview
                  level={"junior"}
                  data={byScore}
                  note={"BECE Subject Grade range from 1 – 7."}
                />
                <GradeOverview
                  level={"senior"}
                  data={byScore}
                  note={"WASSCE Subject Grade range from 1 – 9 (A – F)."}
                />
              </BoxInner>
            </Box>
          </div>
        )}
      </div>
    </Page>
  );
};

export default LearningOutcomes;
