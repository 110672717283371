import React from "react";
import s from "./styles.module.css";
import cn from "classnames";
import { scaleLinear } from "d3-scale";
import roundTo from "../../../helpers/roundTo";

const PercentageChange = ({ change, min, max, score, isBackwards }) => {
  if (isNaN(change) || change === null) {
    return <div className={s.chart} />;
  }

  const x = scaleLinear().domain([min, max]).range([0, 1]);

  const width = x(change);

  const isNegative = (width) => {
    if (isBackwards) {
      return width < 0.5;
    }

    return width > 0.5;
  };

  return (
    <div className={s.chart}>
      <div
        className={cn(s.block, { [s.blockNegative]: isNegative(width) })}
        style={{
          "--width": Math.abs(width - 0.5),
        }}
      >
        <div className={s.value}>
          {change < 0 && (
            <sup className={s.percentage}>{roundTo(change, 1)}%</sup>
          )}
          {roundTo(score, 1)}
          {change > 0 && (
            <sup className={s.percentage}>+{roundTo(change, 1)}%</sup>
          )}
        </div>
      </div>
    </div>
  );
};

export default PercentageChange;
