export const header = {
  name: "DSTI Education Data Hub",
  navOptions: {
    overview: "Overview",
    schools: "Schools Directory",
    explore: "Exams Explorer",
    compare: "Indicators Explorer",
    map: "Indicators Map",
  },
};

export const home = {
  name: "Home",
  title: "Welcome to the Government of Sierra Leone Education Data Hub",
  buttonText: "Go",
  strapline:
    "The implementation of the Government of Sierra Leone’s flagship Free Quality Education Program relies on accurate data, state-of-the-art analytics and near real-time decision support tools across all levels.",
  strapline2: `This Data Hub currently connects the Annual School Census (Ministry of Basic and Senior Secondary Education, ${process.env.REACT_APP_DATA_YEAR}) and the National Examination Results (West African Examination Council, 2016-${process.env.REACT_APP_DATA_YEAR}). It serves as a tool to support decision making through an evidence-based and data-driven process for research, informed policy, planning and evaluation of interventions across schools. Have fun exploring!`,
  sections: [
    {
      name: "Overview",
      copy:
        "Key performance metrics, from approval status to exam performance by school type, district and subjects.",
      url: "/overview",
    },
    {
      name: "Schools Directory",
      copy:
        "Explore full data on all schools with the ability to search, filter and download data for specific schools.",
      url: "/schools",
    },
    {
      name: "Exams Explorer",
      copy:
        "Explore the impact of a specific indicator on learning outcomes (WAEC national examination results) of schools.",
      url: "/explorer",
    },
    {
      name: "Indicators Explorer",
      copy:
        "Find out how two indicators interrelate and impact learning outcomes (WAEC national examination results) of schools.",
      url: "/compare",
    },
    {
      name: "Indicators Map",
      copy:
        "Interact with a colour-coded map of all schools and see their current states for key indicators.",
      url: "/map",
    },
  ],
};

export const footer = {
  text: "",
};

export const generic = {
  noData: "No data",
};

export const schoolsIndex = {
  title: "Showing $numberOfSchools schools for 2019",
  table: {
    noSchools: "No schools",
    schoolName: "School name",
    approval: "Approval status",
    educationLevel: "Education level",
    district: "District",
    passRate: "Pass rate (2019)",
    buttonText: "Full details",
  },
  actions: {
    searchText: "Search school",
    filterText: "Filter schools",
    addFilter: "Add filter",
    nullState: "Please select a filter",
    removeFilter: "Remove filter",
  },
};

export const school = {
  downloadButton: "Download data as csv",
  boxTitles: {
    keyInfo: "Key Info",
    approval: "Approval Status",
    exams: "Exam Performance",
    statistics: "School Factsheet",
  },
  approval: {
    water: "Water",
    latrines: "Toilets",
  },
  performance: {
    marks: "$examName average $scoreGrade",
    passRate: "$examName pass rate",
    studentsSat: "Number of students taking the $examName",
  },
};

export const chartTypes = {
  percentageChange: "Divergence from average $examName score",
  distribution: "Distribution of $examName scores",
  numberOfSchools: "Number of schools",
};

export const explore = {
  title: "Explore the impact of a single indicator on exam performance",
  options: {
    educationLevel: "Select education level",
    chooseMetric: "Select indicator",
  },
  metricTitle:
    "In $examName schools, what effect does $sentenceBuilder have on exam performance?",
  schoolsAxis: "Schools",
  footer: "$schools schools did not answer this question.",
};

export const compare = {
  title: "Explore the impact of two indicators on exam performance",
  options: {
    educationLevel: "Select education level",
    chartType: "Select results display",
    metricsTitle: "",
    chooseMetricOne: "Select first indicator",
    chooseMetricTwo: "Select second indicator",
    switchTitle: "Switch indicators",
  },
  footer: "$schools schools did not answer both these questions.",
  chartTitle:
    "$oneSentenceBuilder <em>versus</em> $twosentenceBuilder in $exam schools",
};

export const compareMetricViz = {
  resultHeader: "Distribution of exam results",
  heatmapHeader: "Darker colors equal more schools",
  histogramHeader: "Number of schools",
  percentageHeader: "Percentage change from average exam result",
  resultFooter: "Average aggregate NPSC exam result",
  histogramFooter: "Schools",
  percentageFooter: "% change",
};

export const map = {
  title: "Map",
  options: {
    educationLevel: "Select education level",
    chooseMetric: "Select indicator",
  },
};
